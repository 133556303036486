// fake data generator
export const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`
  }))

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Moves an item from one list to another list.
 */
export const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

/**
 * Replace an item from one list to another list.
 */
export const replace = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const srcClone = Array.from(source)
  const destClone = Array.from(destination)
  const [srcRemoved] = srcClone.splice(droppableSource.index, 1)
  const [destRemoved] = destClone.splice(droppableDestination.index, 1)

  if (destRemoved) {
    srcClone.splice(droppableSource.index, 0, destRemoved)
    destClone.splice(droppableDestination.index, 0, srcRemoved)
  } else {
    const [beforeRemoved] = destClone.splice(droppableDestination.index - 1, 1)
    srcClone.splice(droppableSource.index, 0, beforeRemoved)
    destClone.splice(droppableDestination.index - 1, 0, srcRemoved)
  }

  const result = {}
  result[droppableSource.droppableId] = srcClone
  result[droppableDestination.droppableId] = destClone

  return result
}
