import React, { Component } from "react"
import PropTypes from "prop-types"
import { Draggable } from "react-beautiful-dnd"

import NaturalDragAnimation from "./animation"
import { formatTime, calculatePlayedTimeForAthlete } from "../../../utils/TimerUtils"

class Item extends Component {
  render() {
    const { item, index, isStartable, isRunning, ...props } = this.props
    const athleteGameTime = calculatePlayedTimeForAthlete(item, props.gameTime)
    return (
      <Draggable draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <NaturalDragAnimation
            style={provided.draggableProps.style}
            snapshot={snapshot}
            {...props}
          >
            {(style) => {
              const fName = item.name.split(" ")[0]
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={style}
                  className="flex flex-col w-10 h-10 sm:w-10 sm:h-10 md:w-12 md:h-12 lg:w-12 lg:h-12 xl:w-12 xl:h-12 rounded-full bg-blue-500 items-center justify-center font-semibold text-xs"
                >
                  <div className={`text-xxs sm:text-xxs+ md:text-xxs+ lg:text-xxs+ xl:text-xxs+ ${athleteGameTime > props.avgTime.athleteAvgTime * 1.2 ? 'text-red-700' : ''}`} style={{height: '12px'}}>{(isRunning || props.gameTime === 0) ? formatTime(athleteGameTime) : ''}</div>
                  <div className="w-full truncate px-1 text-center text-white text-xxs sm:text-xxs+ md:text-xxs+ lg:text-xxs+ xl:text-xxs+" style={{height: '16px', width: '100%'}}>{fName}</div>
                  <div className="text-xxs sm:text-xxs+ md:text-xxs+ lg:text-xxs+ xl:text-xxs+" style={{height: '10px'}}>{isStartable ? `${item.jersey ? item.jersey : ''}` : ''}</div>
                </div>
              )
            }}
          </NaturalDragAnimation>
        )}
      </Draggable>
    )
  }
}

Item.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  // key: PropTypes.string,
  index: PropTypes.number.isRequired,
  isStartable: PropTypes.bool.isRequired,
  isRunning: PropTypes.bool.isRequired
}

export default Item
