import * as ActionTypes from '../config/ActionTypes'
import { apiBaseUrl } from '../config/Constants'

export const userEmail = (email) => {
    return async dispatch => {
        await dispatch({
            type: ActionTypes.USER_EMAIL,
            payload: email
        })
    }
}

export const registerToken = (token) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.REGISTER_TOKEN,
            payload: token
        })
    }
}

export const currentUser = (user) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.CURRENT_USER,
            payload: user
        })
    }
}

export const selectTeam = (team) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.SELECT_TEAM,
            payload: team
        })
    }
}

export const getAllTeams = (teams) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.ALL_TEAMS,
            payload: teams
        })
    }
}

export const getOtherTeams = (teams) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.OTHER_TEAMS,
            payload: teams
        })
    }
}

export const setUnVisibleTeams = (teams) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.UNVISIBLE_TEAMS,
            payload: teams
        })
    }
}

export const setFeedback = (feedback) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.USER_FEEDBACK,
            payload: feedback
        })
    }
}

export const changeEditingPlayers = (players) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.CHNAGE_EDITING_PLAYERS,
            payload: players
        })
    }
}

export const serviceRequest = (endpoint, method, body = null, isLocalLoading, successCallback = null, failCallback = null) => {
    return async dispatch => {
        if (!isLocalLoading) {
            dispatch({
                type: ActionTypes.SERVICE_REQUEST
            });
        }
        const apiUrl = `${apiBaseUrl}/${endpoint}`
        
        // eslint-disable-next-line no-undef
        await fetch(apiUrl, {
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: !!body && body
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    if (failCallback) {
                        failCallback(json)
                    }
                    if (!isLocalLoading) {
                        dispatch({
                            type: ActionTypes.SERVICE_REQUEST_FAIL
                        })
                    }
                } else {
                    if (successCallback) {
                        successCallback(json)
                    }
                    if (!isLocalLoading) {
                        dispatch({
                            type: ActionTypes.SERVICE_REQUEST_SUCCESS
                        });
                    }
                }
            })
            .catch(error => {
                if (failCallback) {
                    failCallback(error)
                }
                if (!isLocalLoading) {
                    dispatch({
                        type: ActionTypes.SERVICE_REQUEST_FAIL
                    })
                }
            })
    }
}