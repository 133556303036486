import * as ActionTypes from '../config/ActionTypes';

const initialState = {
    selectedGame: null,
    progressGames: []
};

const GameReducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.SELECT_GAME:
            return {
                ...state,
                selectedGame: action.payload
            }
        case ActionTypes.PROGRESS_GAMES:
            return {
                ...state,
                progressGames: action.payload
            }
        default: 
            return state;
    }
};

export default GameReducer;