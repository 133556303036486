import React, { Component } from "react"
import PropTypes from "prop-types"
import { Draggable } from "react-beautiful-dnd"

import NaturalDragAnimation from "./animation"
import { formatTime, calculatePlayedTimeForAthlete } from "../../../utils/TimerUtils"

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "#4299e1" : "green",
  // styles we need to apply on draggables
  ...draggableStyle,
  borderRadius: isDragging ? "50%" : "50%",
  // width: '48px',
  // height: '48px'
})

class GridItem extends Component {
  render() {
    const { item, index, isStartable, isRunning, onClickChangeStatus, ...props } = this.props
    const athleteGameTime = calculatePlayedTimeForAthlete(item, props.gameTime)
  
    return (
      <div key={index} className="w-full h-full flex text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
        <div className="w-12 h-12 flex justify-center items-center">
          {item.ready ? (
            <Draggable draggableId={item.id} index={index} Draggable={false} >
              {(provided, snapshot) => (
                <NaturalDragAnimation
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  snapshot={snapshot}
                  {...props}
                >
                  {(style) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={style}
                      className="w-10 h-10 sm:w-10 sm:h-10 md:w-12 md:h-12 lg:w-12 lg:h-12 xl:w-12 xl:h-12 flex justify-center items-center"
                    >
                      <span>{item.name.charAt(0).toUpperCase()}</span>
                    </div>
                  )}
                </NaturalDragAnimation>
              )}
            </Draggable>
          ) : (
            <div className="w-10 h-10 sm:w-10 sm:h-10 md:w-12 md:h-12 lg:w-12 lg:h-12 xl:w-12 xl:h-12 flex justify-center items-center rounded-full" style={{background: 'gray'}}>
              <span >{item.name.charAt(0).toUpperCase()}</span>
            </div>
          )}
        </div>
        <div className="w-full h-12 flex items-center">
          <div className="w-32 xs:w-32 sm:w-32 md:w-48 lg:w-56 xl:w-64 block px-2 truncate ">{`${item.name}`}</div>
          {isStartable && <div className="w-12 flex bg-gray-500 h-full px-2 justify-center items-center font-semibold cursor-default">{item.jersey ? item.jersey : ''}</div>}
          <div className="w-1/3 h-full flex px-2 items-center font-semibold cursor-default ">{`${item.pos ? item.pos : ''}`}</div>
          {isRunning ? (
            <div
              className={
                item.ready
                  ? `w-1/2 h-full flex items-center font-semibold px-2 ${athleteGameTime < props.avgTime.athleteAvgTime * 0.8 ? 'text-red-700' : ''}`
                  : "w-1/2 h-full flex items-center bg-red-700 font-semibold cursor-pointer px-2 "
              }
            >
              {item.ready ? formatTime(athleteGameTime) : "Absent"}
            </div>
          ) : (
            <div
              className={`w-1/2 h-full flex items-center font-semibold px-2 ${item.ready ? 'bg-green-500' : 'bg-red-700'} cursor-pointer`}
              onClick={() => onClickChangeStatus(item)}
            >
              {item.ready ? "Ready to Play" : "Absent"}
            </div>
          )}
        </div>
      </div>
    )
  }
}

GridItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  // key: PropTypes.string.isRequired,
  isRunning: PropTypes.bool.isRequired,
  isPaused: PropTypes.bool.isRequired,
  isStartable: PropTypes.bool.isRequired,
  onClickChangeStatus: PropTypes.func.isRequired
}

export default GridItem
