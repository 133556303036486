export const USER_EMAIL = 'User/USER_EMAIL'
export const REGISTER_TOKEN = 'User/REGISTER_TOKEN'
export const CURRENT_USER = 'User/CURRENT_USER'
export const SELECT_TEAM = 'User/SELECT_TEAM'
export const ALL_TEAMS = 'User/ALL_TEAMS'
export const OTHER_TEAMS = 'User/OTHER_TEAMS'
export const UNVISIBLE_TEAMS = 'User/UNVISIBLE_TEAMS'
export const SERVICE_REQUEST = 'User/SERVICE_REQUEST'
export const SERVICE_REQUEST_SUCCESS = 'User/SERVICE_REQUEST_SUCCESS'
export const SERVICE_REQUEST_FAIL = 'User/SERVICE_REQUEST_FAIL'
export const USER_FEEDBACK = 'User/USER_FEEDBACK'
export const CHNAGE_EDITING_PLAYERS = 'User/CHANGE_EDITING_PLAYERS'

export const SELECT_GAME = 'Game/SELECT_GAME'
export const PROGRESS_GAMES = 'Game/PROGRESS_GAMES'
