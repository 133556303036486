import React, { Component } from "react"
import PropTypes from "prop-types"
import { DragDropContext } from "react-beautiful-dnd"
import GridColumn from "./gridColumn"
import OneColumn from "./onecolumn"
import DropDownSmall from "../../dropdown/drowdownsmall"
import { formatTime } from "../../../utils/TimerUtils"

const stopIcon = require("../../../../images/icons/stop.png")
const playIcon = require("../../../../images/icons/play.png")
const pauseIcon = require("../../../../images/icons/pause.png")

class Grid extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      state,
      gameTime,
      isStartable,
      formations,
      formationIndex,
      avgTime,
      isRunning,
      isPaused,
      onDragEnd,
      gameTimeFocused,
      gameDuration,
      onChangeGameTime,
      onFocusAndBlurGameTime,
      onClickStart,
      onClickStop,
      onChangeFormation,
    } = this.props

    let buttonText = `Add ${11 - formationIndex} players to begin`
    if (isStartable) {
      if (!isRunning && gameTime === 0) {
        buttonText = "Start Game"
      } else {
        if (isPaused) {
          buttonText = "Resume Game"
        } else {
          buttonText = "Pause Game"
        }
      }
    }

    return (
      <div className="w-full p-2 border border-black">
        <DragDropContext className="w-full h-full" onDragEnd={(res) => onDragEnd(res)}>
          <div className="stadium-image-bg w-full block" style={{height: '40vh'}}>
            <div className="flex w-full px-2" style={{height: '10vh'}}>
              <div
                className="flex w-full h-full items-center justify-center"
                style={{ width: "27%" }}
              >
                <OneColumn droppableId="posLF" data={state.posLF} placeholder="LF" {...this.props} />
              </div>
              <div
                className="flex w-full h-full items-center justify-between border border-white"
                style={{ width: "46%" }}
              >
                <OneColumn
                  droppableId="posCF"
                  data={state.posCF}
                  {...this.props}
                />
              </div>
              <div
                className="flex w-full h-full items-center justify-center"
                style={{ width: "27%" }}
              >
                <OneColumn droppableId="posRF" data={state.posRF} {...this.props} />
              </div>
            </div>
            <div className="flex w-full px-2" style={{height: '10vh'}}>
              <div
                className="flex w-full h-full items-center justify-center"
                style={{ width: "27%" }}
              >
                <OneColumn droppableId="posLM" data={state.posLM} {...this.props} />
              </div>
              <div
                className="flex w-full h-full items-center justify-between"
                style={{ width: "46%" }}
              >
                <OneColumn
                  droppableId="posCM"
                  data={state.posCM}
                  {...this.props}
                />
              </div>
              <div
                className="flex w-full h-full items-center justify-center"
                style={{ width: "27%" }}
              >
                <OneColumn droppableId="posRM" data={state.posRM} {...this.props} />
              </div>
            </div>
            <div className="flex w-full px-2" style={{height: '10vh'}}>
              <div
                className="flex w-full h-full items-center justify-center"
                style={{ width: "27%" }}
              >
                <OneColumn droppableId="posLB" data={state.posLB} {...this.props} />
              </div>
              <div
                className="flex w-full h-full items-center justify-between"
                style={{ width: "46%" }}
              >
                <OneColumn
                  droppableId="posCB"
                  data={state.posCB}
                  {...this.props}
                />
              </div>
              <div
                className="flex w-full h-full items-center justify-center"
                style={{ width: "27%" }}
              >
                <OneColumn droppableId="posRB" data={state.posRB} {...this.props} />
              </div>
            </div>
            <div className="flex w-full px-2 items-center justify-center" style={{height: '10vh'}}>
              {/* <span className="font-bold text-2xl">GK</span> */}
              <OneColumn droppableId="posGK" data={state.posGK} {...this.props} />
            </div>
          </div>

          <div className="w-full h-12 flex">
            <div className="flex w-full h-full items-center bg-yellow-400">
              <div className="flex w-1/2 px-2 items-center h-10 text-xs">
                <div className="w-32 flex flex-wrap items-center justify-center">
                  <DropDownSmall
                    className="w-full"
                    options={formations}
                    name="formation"
                    placeholder="Formation"
                    selectedIndex={formationIndex}
                    onChange={onChangeFormation}
                  />
                  <span className="w-full" style={{height: '12px'}} />
                </div>
                <span className="px-1" />
                <div className="w-20 flex flex-wrap items-center justify-center">
                  <input 
                    className="w-full bg-white font-bold text-center"
                    style={{height: '18px'}}
                    value={gameTimeFocused ? gameDuration : formatTime(gameDuration * 60 - gameTime)}
                    name="gameTime"
                    disabled={gameTime > 0}
                    onChange={onChangeGameTime}
                    onFocus={onFocusAndBlurGameTime}
                    onBlur={onFocusAndBlurGameTime}
                  />
                  <span className="w-full" style={{height: '12px', fontSize: 8}}>{`${formatTime(avgTime.gameAvgTime)} Per Player`}</span>
                </div>
              </div>
              {gameTime > 0 ? (
                <div className="flex w-12 h-full justify-center items-center">
                  <img
                    className="cursor-pointer w-6 h-6 sm:w-6 sm:h-6 xl:w-8 xl:h-8 md:w-8 md:h-8 lg:w-8 lg:h-8"
                    src={stopIcon}
                    alt="stop"
                    onClick={onClickStop}
                  />
                </div>
              ) : (
                <div className="w-12 h-full"></div>
              )}
              <div className="flex w-1/2 h-10 flex px-2 justify-end">
                <div className="flex w-full h-full px-2 bg-gray-700 items-center justify-between">
                  <div className="flex h-full w-full items-center">
                    <span className="font-bold text-white text-xs">
                      {buttonText}
                    </span>
                  </div>
                  <div className="h-12 w-12 flex items-center justify-center">
                    <img
                      className={
                        isStartable
                          ? "cursor-pointer w-6 h-6 sm:w-6 sm:h-6 xl:w-8 xl:h-8 md:w-8 md:h-8 lg:w-8 lg:h-8"
                          : "w-6 h-6 sm:w-6 sm:h-6 xl:w-8 xl:h-8 md:w-8 md:h-8 lg:w-8 lg:h-8 pointer-events-none"
                      }
                      src={isPaused ? playIcon : pauseIcon}
                      alt="play"
                      onClick={onClickStart}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <div className="w-full overflow-y-scroll" style={{height: '30vh'}}>
            <GridColumn
              droppableId="bench"
              data={state.bench}
              {...this.props}
            />
          </div>
        </DragDropContext>
      </div>
    )
  }
}

Grid.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  gameTime: PropTypes.number.isRequired,
  isStartable: PropTypes.bool.isRequired,
  avgTime: PropTypes.instanceOf(Object).isRequired,
  formations: PropTypes.instanceOf(Array).isRequired,
  formationIndex: PropTypes.number.isRequired,
  isRunning: PropTypes.bool.isRequired,
  isPaused: PropTypes.bool.isRequired,
  gameTimeFocused: PropTypes.bool.isRequired,
  gameDuration: PropTypes.number.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onChangeGameTime: PropTypes.func.isRequired,
  onClickStart: PropTypes.func.isRequired,
  onClickStop: PropTypes.func.isRequired,
  onFocusAndBlurGameTime: PropTypes.func.isRequired,
  onChangeFormation: PropTypes.func.isRequired
}

export default Grid
