
export const ToastMessages = {
  INVITE_SUCCESS: 'Successfully Invited',
  INVITE_FAIL: 'Invite Sent Failed',

  LOGIN_SUCCESS: 'Successfully Signed In',
  LOGIN_FAIL: 'SignIn Failed',

  CREATE_TEAM_SUCCESS: 'Your Team Successfully Created',
  CREATE_TEAM_FAIL: 'Team Creation Failed',

  CREATE_GAME_SUCCESS: 'Successfully Game Created',
  CREATE_GAME_FAIL: 'Game Creation Failed',

  GAME_FINISHED: 'Current Game Was Finished',
  GAME_STARTED: 'Game Started By',
  GAME_PAUSED: 'Game Paused By',
  GAME_RESUMED: 'Game Resumed By',
}