import React from "react"
// import { connect } from "react-redux"
import { Route } from "react-router-dom"
// import PropTypes from "prop-types"
import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import MainApp from "../routes/app"
import LoginPage from "../routes/Login"
import SubmitDetailsPage from "../routes/SubmitDetails"
import {
  CreateTeamPage,
  AutoSetupTeamPage
} from "../routes/CreateTeam"
import TeamConfirmationPage from "../routes/TeamConfirmation"
import { ViewTeamPage, ViewAllTeamsPage } from "../routes/ViewTeam"
import { RatePage, FullRatedPage, UnFullRatedPage } from "../routes/RateApp"
import AddOtherTeamPage from "../routes/AddTeam"
import ManageSubsAllPage from "../routes/ManageSubs"
import GameInPage from "../routes/GameIn"
import {PrivacyPage, TermsPage} from "../routes/TermsAndPrivacy"

export default class Root extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="main-bg-color">
        <Route exact path="/" component={MainApp} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/submit-details" component={SubmitDetailsPage} />
        <Route exact path="/create-team" component={CreateTeamPage} />
        <Route exact path="/autosetup-team" component={AutoSetupTeamPage} />
        <Route exact path="/team-confirmation" component={TeamConfirmationPage} />
        <Route exact path="/view-team" component={ViewTeamPage} />
        <Route exact path="/view-allteam" component={ViewAllTeamsPage} />
        <Route exact path="/rate-app" component={RatePage} />
        <Route exact path="/rate-app/full-rated" component={FullRatedPage} />
        <Route exact path="/rate-app/unfull-rated" component={UnFullRatedPage} />
        <Route exact path="/add-team" component={AddOtherTeamPage} />
        <Route exact path="/manage-subs" component={ManageSubsAllPage} />
        <Route exact path="/game-in" component={GameInPage} />
        <Route exact path="/linkedin" component={LinkedInPopUp} />
        <Route exact path="/privacy-policy" component={PrivacyPage} />
        <Route exact path="/terms-of-use" component={TermsPage} />
      </div>
    )
  }
}
