import React from "react"
import { connect } from "react-redux";
import PropTypes from "prop-types"
import * as firebase from 'firebase'
import styles from "./styles"
import Button from "../../components/buttons/button"
import { Grid } from "../../components/draggable"
import Spinner from "../../components/spinner/spinner"
import ConfirmDialog from "../../components/dialogs/confirmDialog"
import { reorder, move, replace } from "../../utils/ArrayUtils"
import { serviceRequest, selectTeam } from "../../../actions/User"
import { selectGame } from "../../../actions/Game"
import { saveGame, updateGame, deleteGame } from "../../utils/FirestoreUtils"
import { calculatePlayedTimeForAthlete, getCurrentUTCSeconds } from "../../utils/TimerUtils"

const formations = [
  '11 vs 11',
  '10 vs 10',
  '9 vs 9',
  '8 vs 8',
  '7 vs 7',
  '6 vs 6',
  '5 vs 5',
  '4 vs 4',
  '3 vs 3',
]

const gameDb = firebase.firestore()

class GameIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        bench: [],
        posGK: [],
        posLB: [],
        posRB: [],
        posCB: [],
        posLM: [],
        posCM: [],
        posRM: [],
        posLF: [],
        posCF: [],
        posRF: []
      },
      isRunning: false,
      isPaused: true,
      gameTime: 0,
      gameData: null,
      gameDuration: 60,
      gameTimeFocused: false,
      stopModalVisible: false,
      gameStarted: false,
      formationIndex: 0
    }
    this.onChangeGameTime = this.onChangeGameTime.bind(this)
    this.onFocusAndBlurGameTime = this.onFocusAndBlurGameTime.bind(this)

    this.onDragEnd = this.onDragEnd.bind(this)
    this.onClickStart = this.onClickStart.bind(this)
    this.onClickStop = this.onClickStop.bind(this)
    this.onClickChangeStatus = this.onClickChangeStatus.bind(this)
    this.onClickStopModal = this.onClickStopModal.bind(this)
    this.onClickExit = this.onClickExit.bind(this)
    this.onClickSaveAndExit = this.onClickSaveAndExit.bind(this)
  }

  columnList = {
    bench: "bench",
    posGK: "posGK",
    posLB: "posLB",
    posCB: "posCB",
    posRB: "posRB",
    posLM: "posLM",
    posRM: "posRM",
    posCM: "posCM",
    posLF: "posLF",
    posRF: "posRF",
    posCF: "posCF"
  }

  onBackButtonEvent = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { history } = this.props
    history.push('/')
  }

  componentDidMount = async() => {
    const { user, game, serviceRequest } = this.props
    const { selectedGame } = game
    window.onpopstate = this.onBackButtonEvent;
    if (selectedGame) {
      this.gameSubscribe(selectedGame.id)
    } else if (user.selectedTeamInfo) {
      const body = JSON.stringify({
        teamId: user.selectedTeamInfo.id
      })
      await serviceRequest('athlete/getAthletesByTeamId', 'POST', body, false, this.requestAthletes.bind(this));
    }
  }

  componentWillUnmount() {
    const { selectTeam, selectGame } = this.props
    selectTeam(null)
    selectGame(null)
    if (this.unsubscribe) {
      this.unsubscribe()
    }
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  requestAthletes = (result) => {
    if (!result.error) {
      const athletes = []
      result.map((player, index) => {
        if (!player.hidden) {
          const positions = player.preferred_positions
          ? JSON.parse(player.preferred_positions)
          : [];

          const prefer_pos =
            positions && positions.length > 0 ? positions.join(', ') : '';
          const athlete = {
            athleteId: player.id,
            name: player.last_name
              ? `${player.first_name} ${player.last_name}`
              : player.first_name,
            jersey: player.jersey_number ? player.jersey_number : '',
            pos: prefer_pos ? prefer_pos : '',
            id: `item-${index}`,
            joinTime: -1,
            outTime: -1,
            ready: true,
          };
          athletes.push(athlete)
        }
      })
      athletes.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      
      const { data } = this.state
      this.setState({
        data: {
          ...data,
          bench: athletes
        }
      })
    }
  }

  startTimer = () => {  
    this.timer = setInterval(() => {
      const { gameData } = this.state
      if (gameData && gameData.isPlaying) {
        this.setState({
          gameTime: this.getGameTime()
        })
      }
    }, 1000);
  }

  getList = (id) => {
    const { data } = this.state
    return data[this.columnList[id]]
  }

  getPositionedPlayers = () => {
    const { data } = this.state
    return (
      data.posGK.length +
      data.posLB.length +
      data.posCB.length +
      data.posRB.length +
      data.posLM.length +
      data.posCM.length +
      data.posRM.length +
      data.posLF.length +
      data.posCF.length +
      data.posRF.length
    )
  }

  getGameTime = () => {
    const { gameData } = this.state
    const gameTime = gameData.isPlaying ? getCurrentUTCSeconds() - gameData.startTime : gameData.pauseTime - gameData.startTime
    return gameTime <= gameData.gameDuration ? gameTime : gameData.gameDuration
  }

  gameSubscribe = (gameId) => {
    this.unsubscribe = gameDb.collection("games").doc(`game-${gameId}`)
      .onSnapshot(doc => {
        if (doc.exists) {
          const gameData = doc.data()
          this.setState({ 
            gameData,
            data: gameData.mainAthletes,
            isRunning: gameData.isPlaying,
            isPaused: !gameData.isPlaying,
            gameDuration: gameData.gameDuration / 60,
            gameStarted: true
          }, () => {
            // const positionedPlayers = this.getPositionedPlayers()
            // if (positionedPlayers >= 3) {
            //   this.setState({
            //     formationIndex: 11 - positionedPlayers
            //   })
            // }
            this.setState({
              gameTime: gameData.startTime === 0 ? 0 : this.getGameTime()
            })
            if (gameData.isPlaying) {
              if (this.timer) {
                clearInterval(this.timer)
              }
              this.startTimer()
            }
          })
        } else {
          this.setState({
            gameData: null
          }, () => {
            const { gameStarted } = this.state
            if (gameStarted) {
              this.props.history.push("/")
            }
          })
        }
      }, error => {
        console.log('********: ', error)
      })
  }

  createGameSuccess = async (createdGame) => {
    if (!createdGame.error) {
      const { user, selectGame } = this.props
      const { data, gameDuration } = this.state
      selectGame(createdGame)
      
      const subsManagers = [user.userInfo.id]
      const storingGame = {
        gameDuration : gameDuration * 60,
        startTime: getCurrentUTCSeconds(),
        pauseTime: 0,
        oppAthletes: [],
        mainAthletes: data,
        mainAthleteStats: [],
        oppAthleteStats: [],
        controlUserId: user.userInfo.id,
        isScrimming: false,
        gameId: createdGame.id,
        isPlaying: true,
        mainGoals: 0,
        oppGoals: 0,
        subsManagers
      }
      await saveGame(`game-${createdGame.id}`, storingGame)
      this.gameSubscribe(createdGame.id)
      this.startTimer()
    }
  }

  onClickStart = () => {
    const { data, gameData, gameTime, gameDuration } = this.state
    const { game, user, serviceRequest } = this.props
    const { selectedGame } = game

    const filteredAthletes = data.bench.filter(athlete => athlete.ready)
    filteredAthletes.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    const absentAthletes = data.bench.filter(athlete => !athlete.ready)
    
    if (!selectedGame && !gameData) {
      const { selectedTeamInfo } = user
      const body = JSON.stringify({
        mainTeamId: selectedTeamInfo.id,
        oppTeamId: '',
        isScrimming: false
      })
      
      this.setState({
        data: {
          ...data,
          bench: [...filteredAthletes, ...absentAthletes]
        }
      }, () => {
        serviceRequest("game/create", "POST", body, false, this.createGameSuccess.bind(this))
      })
      return
    }

    if (gameData) {
      let subsManagers = gameData.subsManagers
      if (subsManagers.indexOf(user.userInfo.id) === -1)
      {
        subsManagers.push(user.userInfo.id)
      }

      if (parseInt(gameDuration) !== 60) {
        updateGame(`game-${gameData.gameId}`, {
          gameDuration: parseInt(gameDuration) * 60,
        });
      }

      let mainAthletes = gameData.mainAthletes

      const absentAthletes = mainAthletes.bench.filter(item => !item.ready)
      absentAthletes.sort((a, b) => {
        const aTime = calculatePlayedTimeForAthlete(a, gameTime)
        const bTime = calculatePlayedTimeForAthlete(b, gameTime)
        return aTime - bTime
      })
      const readyAthletes = mainAthletes.bench.filter(item => item.ready)
      readyAthletes.sort((a, b) => {
        const aTime = calculatePlayedTimeForAthlete(a, gameTime)
        const bTime = calculatePlayedTimeForAthlete(b, gameTime)
        return aTime - bTime
      })
      mainAthletes = {
        ...mainAthletes,
        bench: [
          ...readyAthletes,
          ...absentAthletes
        ]
      }

      const status = {
        isPlaying: !gameData.isPlaying,
        subsManagers,
        mainAthletes
      }
      updateGame(`game-${gameData.gameId}`, status)

      if (gameData.isPlaying) {
        if (this.timer) {
          clearInterval(this.timer)
          updateGame(`game-${gameData.gameId}`, { pauseTime: getCurrentUTCSeconds() })
        }
      } else {
        updateGame(`game-${gameData.gameId}`, { startTime: gameData.startTime + (getCurrentUTCSeconds() - gameData.pauseTime) })
        this.startTimer()
        updateGame(`game-${gameData.gameId}`, { controlUserId: user.userInfo.id })
      }
    }
  }

  onDragEnd = (res) => {
    const { data, gameTime, gameData, formationIndex } = this.state
    const { source, destination } = res
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      )

      this.setState({
        data: {
          ...data,
          [source.droppableId]: items
        }
      }, () => {
        const { data } = this.state
        if (gameTime > 0 && gameData) {
          const chnData = {
            mainAthletes: data
          }
          updateGame(`game-${gameData.gameId}`, chnData)
        }
      })
    } else {
      let result = null
      let outedAthlete = null
      let joinedAthlete = null
      const positionedPlayers = this.getPositionedPlayers()

      const limitPlayers = 11 - formationIndex;
      
      if (source.droppableId === "bench") {
        if (destination.droppableId === "posGK" && data[destination.droppableId].length === 0 && positionedPlayers < limitPlayers ||
          (destination.droppableId === "posLB" ||
            destination.droppableId === "posRB" ||
            destination.droppableId === "posLM" ||
            destination.droppableId === "posRM" ||
            destination.droppableId === "posLF" ||
            destination.droppableId === "posRF"
          ) && data[destination.droppableId].length < 2 && positionedPlayers < limitPlayers ||
          (
            destination.droppableId === "posCB" ||
            destination.droppableId === "posCM" ||
            destination.droppableId === "posCF"
          ) && data[destination.droppableId].length < 3 && positionedPlayers < limitPlayers
        ) {
          result = move(
            this.getList(source.droppableId),
            this.getList(destination.droppableId),
            source,
            destination
          )
          joinedAthlete = result[destination.droppableId][destination.index]
          if (!joinedAthlete) {
            joinedAthlete = result[destination.droppableId][destination.index - 1]
          }
          if (joinedAthlete) {
            joinedAthlete.joinTime = gameTime - calculatePlayedTimeForAthlete(joinedAthlete, gameTime)
            joinedAthlete.outTime = -1
            const joinedPos = destination.droppableId.substring(3, destination.droppableId.length)
            joinedAthlete.pos = joinedPos
          }
        } else {
          if (data[destination.droppableId].length === 0) {
            if (positionedPlayers === limitPlayers) {
              return
            }
            result = move(
              this.getList(source.droppableId),
              this.getList(destination.droppableId),
              source,
              destination
            )
            joinedAthlete = result[destination.droppableId][destination.index]
            if (!joinedAthlete) {
              joinedAthlete = result[destination.droppableId][destination.index - 1]
            }
            if (joinedAthlete) {
              joinedAthlete.joinTime = gameTime - calculatePlayedTimeForAthlete(joinedAthlete, gameTime)
              joinedAthlete.outTime = -1
              const joinedPos = destination.droppableId.substring(3, destination.droppableId.length)
              joinedAthlete.pos = joinedPos
            }
          } else {
            result = replace(
              this.getList(source.droppableId),
              this.getList(destination.droppableId),
              source,
              destination
            )
            if (gameTime > 0 && gameData) {
              outedAthlete = result[source.droppableId][source.index]
              if (outedAthlete)
                outedAthlete.outTime = gameTime
            }
            else {
              outedAthlete = result[source.droppableId][source.index]
              if (outedAthlete)
                outedAthlete.joinTime = -1
            }
            joinedAthlete = result[destination.droppableId][destination.index]
            
            if (!joinedAthlete) {
              joinedAthlete = result[destination.droppableId][destination.index - 1]
            }
            if (joinedAthlete) {
              joinedAthlete.joinTime = gameTime - calculatePlayedTimeForAthlete(joinedAthlete, gameTime)
              joinedAthlete.outTime = -1
              const joinedPos = destination.droppableId.substring(3, destination.droppableId.length)
              joinedAthlete.pos = joinedPos
            }
          }
        }
      } else {
        if (destination.droppableId === "bench") {
          result = move(
            this.getList(source.droppableId),
            this.getList(destination.droppableId),
            source,
            destination
          )
          if (gameTime > 0 && gameData) {
            outedAthlete = result[destination.droppableId][destination.index]
            if (outedAthlete) {
              outedAthlete.outTime = gameTime
            }
          } else {
            outedAthlete = result[destination.droppableId][destination.index]
            if (outedAthlete) {
              outedAthlete.outTime = -1
              outedAthlete.joinTime = -1
            }
          }
        } else {
          let srcAthlete = null
          let dstAthlete = null
          if (
            destination.droppableId === "posGK" && data.posGK.length === 0 ||
            (
              destination.droppableId === "posLB" ||
              destination.droppableId === "posRB" ||
              destination.droppableId === "posLM" ||
              destination.droppableId === "posRM" ||
              destination.droppableId === "posLF" ||
              destination.droppableId === "posRF"
            ) && data[destination.droppableId].length < 2 ||
            (
              destination.droppableId === "posCB" ||
              destination.droppableId === "posCM" ||
              destination.droppableId === "posCF"
            ) && data[destination.droppableId].length < 3
          ) {
            result = move(
              this.getList(source.droppableId),
              this.getList(destination.droppableId),
              source,
              destination
            )
          } else {
            result = replace(
              this.getList(source.droppableId),
              this.getList(destination.droppableId),
              source,
              destination
            )
            srcAthlete = result[source.droppableId][source.index]
            srcAthlete.pos = source.droppableId.substring(3, source.droppableId.length)
          }
          dstAthlete = result[destination.droppableId][destination.index]
          if (!dstAthlete) {
            dstAthlete = result[destination.droppableId][destination.index - 1]
          }
          dstAthlete.pos = destination.droppableId.substring(3, destination.droppableId.length)
        }
      }
      
      this.setState({
        data: {
          ...data,
          [source.droppableId]: result[source.droppableId],
          [destination.droppableId]: result[destination.droppableId]
        }
      }, () => {
        const { data } = this.state
        const { user } = this.props
        if (gameTime > 0 && gameData) {

          let subsManagers = gameData.subsManagers
          if (subsManagers.indexOf(user.userInfo.id) === -1)
          {
            subsManagers.push(user.userInfo.id)
          }
          const filteredAthletes = data.bench.filter(item => item.ready)
          filteredAthletes.sort((a, b) => {
            const aTime = calculatePlayedTimeForAthlete(a, gameTime)
            const bTime = calculatePlayedTimeForAthlete(b, gameTime)
            return aTime - bTime
          })
          const absentAthletes = data.bench.filter(item => !item.ready)
          absentAthletes.sort((a, b) => {
            const aTime = calculatePlayedTimeForAthlete(a, gameTime)
            const bTime = calculatePlayedTimeForAthlete(b, gameTime)
            return aTime - bTime
          })
          const chnData = {
            mainAthletes: {
              ...data,
              bench: [...filteredAthletes, ...absentAthletes],
            },
            subsManagers
          }
          updateGame(`game-${gameData.gameId}`, chnData)
        }
      })
    }
  }

  onChangeGameTime = (e) => {
    const { target } = e
    const { value } = target
    const regExp = /^\d*\.?\d*$/
    if (regExp.test(value)) {
      this.setState({
        gameDuration: value ? parseInt(value) : 0
      })
    }
  }

  onFocusAndBlurGameTime = () => {
    const { gameTimeFocused } = this.state
    this.setState({
      gameTimeFocused: !gameTimeFocused
    })
  }

  onClickChangeStatus = (athlete) => {
    // if (athlete.outTime === -1) {
      const { data, gameTime } = this.state
      const chnBench = []
      data.bench.map(item => {
        if (item.id === athlete.id) {
          athlete.ready = !athlete.ready
        }
        chnBench.push(item)
      })
      const filteredAthletes = chnBench.filter(athlete => athlete.ready)
      if (gameTime > 0) {
        filteredAthletes.sort((a, b) => {
          const aTime = calculatePlayedTimeForAthlete(a, gameTime)
          const bTime = calculatePlayedTimeForAthlete(b, gameTime)
          return aTime - bTime
        })
      } else {
        filteredAthletes.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      }
      const absentAthletes = chnBench.filter(athlete => !athlete.ready)
      if (gameTime > 0) {
        absentAthletes.sort((a, b) => {
          const aTime = calculatePlayedTimeForAthlete(a, gameTime)
          const bTime = calculatePlayedTimeForAthlete(b, gameTime)
          return aTime - bTime
        })
      } else {
        absentAthletes.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      }
      this.setState({
        data: {
          ...data,
          bench: [
            ...filteredAthletes,
            ...absentAthletes
          ]
        }
      }, () => {
        const { gameData, data } = this.state
        if (gameData) {
          const chnData = {
            mainAthletes: data
          }
          updateGame(`game-${gameData.gameId}`, chnData)
        }
      })
    // }
  }

  getAvgTime = () => {
    const { gameTime, gameDuration, data } = this.state
    // Calculate number of available players
    const athletesInGame = [
      ...data.posGK,
      ...data.posLB,
      ...data.posCB,
      ...data.posRB,
      ...data.posLM,
      ...data.posCM,
      ...data.posRM,
      ...data.posLF,
      ...data.posCF,
      ...data.posRF
    ]
    let availableNumbers = 0
    const allAthletes = [
      ...athletesInGame,
      ...data.bench
    ]
    allAthletes.map(item => {
      if (item.ready) {
        availableNumbers += 1
      }
    })

    if (availableNumbers === 0) {
      availableNumbers = 1
    }

    return {
      gameAvgTime: Math.round(gameDuration * 60 * athletesInGame.length / availableNumbers),
      athleteAvgTime: Math.round(gameTime * athletesInGame.length / (availableNumbers))
    }
  }


  onClickStop = () => {
    const { stopModalVisible, gameData } = this.state
    const { user } = this.props
    if (gameData) {
      const status = {
        isPlaying: false,
        controlUserId: user.userInfo.id
      }
      if (gameData.isPlaying) {
        if (this.timer) {
          clearInterval(this.timer);
          updateGame(`game-${gameData.gameId}`, {
            pauseTime: getCurrentUTCSeconds(),
          })
        }
      }
      updateGame(`game-${gameData.gameId}`, status)
    }
    this.setState({
      stopModalVisible: !stopModalVisible
    })
  }

  onClickStopModal = () => {
    const { stopModalVisible, gameData } = this.state
    this.setState({
      stopModalVisible: !stopModalVisible
    }, () => {
      if (gameData && !gameData.isPlaying) {
        const status = {
          isPlaying: true,
          startTime:
            gameData.startTime +
            (getCurrentUTCSeconds() - gameData.pauseTime),
        };
        updateGame(`game-${gameData.gameId}`, status)
        this.startTimer()
      }
    })
  }

  updateFinishedGameSuccess = (data) => {
    console.log('--------: finished game', data)
    const { history } = this.props
    const { gameData } = this.state
    if (!data.error) {
      if (this.timer) {
        clearInterval(this.timer)
      }
      deleteGame(`game-${gameData.gameId}`)
      if (this.unsubscribe) {
        this.unsubscribe()
      }
      history.push("/")
    }
  }

  calculatePlayedTimeForAthlete = (athlete) => {
    const { gameData, gameTime } = this.state
    if (athlete) {
      if (gameData.isScrimming) {
        return gameTime
      } else {
        if (athlete.joinTime === -1) {
          return 0
        } else {
          if (athlete.joinTime === 0) {
            if (athlete.outTime === -1) {
              return gameTime
            } else {
              return athlete.outTime
            }
          } else {
            if (athlete.outTime === -1) {
              return gameTime - athlete.joinTime
            } else {
              return athlete.outTime - athlete.joinTime
            }
          }
        }
      }
    } else {
      return 0
    }
  }

  onClickSaveAndExit = () => {
    const { serviceRequest } = this.props
    const { gameData, gameTime } = this.state
    
    // Calculate real stats athlete count
    const result = gameData.mainAthleteStats.reduce(function(hash) {
      return function (r, o) {
        if (!hash[o.athleteId]) {
          hash[o.athleteId] = []
          r.push(hash[o.athleteId])
        }
        hash[o.athleteId].push(o)
        return r
      }
    }(Object.create(null)), [])

    const allAthletes = [
      ...gameData.mainAthletes.posGK,
      ...gameData.mainAthletes.posLB,
      ...gameData.mainAthletes.posCB,
      ...gameData.mainAthletes.posRB,
      ...gameData.mainAthletes.posLM,
      ...gameData.mainAthletes.posCM,
      ...gameData.mainAthletes.posRM,
      ...gameData.mainAthletes.posLF,
      ...gameData.mainAthletes.posCF,
      ...gameData.mainAthletes.posRF,
      ...gameData.mainAthletes.bench
    ]

    const playedAthletes = []

    allAthletes.map(athlete => {
      const playedTime = this.calculatePlayedTimeForAthlete(athlete)
      if (playedTime !== 0) {
        const temp = {
          athleteId: athlete.athleteId,
          playedPos: athlete.pos,
          playedTime
        }
        playedAthletes.push(temp)
      }
    })


    const body = JSON.stringify({
      gameId: gameData.gameId,
      gameDuration: gameTime,
      mainGoals: gameData.mainGoals,
      oppGoals: gameData.oppGoals,
      mainStatsInfo: gameData.mainAthleteStats,
      oppStatsInfo: gameData.oppAthleteStats,
      statsAthleteCount: result.length,
      subsManagers: gameData.subsManagers,
      playedAthletes
    })

    console.log('------: ', body)
    
    serviceRequest("game/updateFinishedStatsGame", "POST", body, false, this.updateFinishedGameSuccess.bind(this))
  }

  onClickExit = () => {
    const { serviceRequest } = this.props
    const { gameData } = this.state
    const body = JSON.stringify({
      gameId: gameData.gameId
    })
    serviceRequest("game/deleteGameById", "POST", body, false, this.updateFinishedGameSuccess.bind(this))
  }

  onClickGoHome = () => {
    const {history} = this.props;
    history.push('/');
  }

  onChangeFormation = (e) => {
    const { target } = e;
    const { value } = target;
    this.setState({
      formationIndex: value
    })
  }

  render() {
    const {
      data,
      isRunning,
      isPaused,
      gameTime,
      gameTimeFocused,
      gameDuration,
      stopModalVisible,
      gameData,
      formationIndex
    } = this.state
    const { user } = this.props

    if (gameData && !gameData.isPlaying && gameData.gameTime !== 0) {
      if (this.timer) {
        clearInterval(this.timer)
      }
    }

    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <div className="max-w-2xl xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mb-4 bg-green rounded-lg">
          <Grid
            state={data}
            gameTime={gameTime}
            isStartable={this.getPositionedPlayers() >= (11 - formationIndex) || gameTime > 0}
            avgTime={this.getAvgTime()}
            formations={formations}
            formationIndex={formationIndex}
            isRunning={isRunning}
            isPaused={isPaused}
            gameTimeFocused={gameTimeFocused}
            gameDuration={gameDuration}
            onDragEnd={this.onDragEnd}
            onChangeGameTime={this.onChangeGameTime}
            onClickStart={this.onClickStart}
            onClickStop={this.onClickStop}
            onClickChangeStatus={this.onClickChangeStatus}
            onFocusAndBlurGameTime={this.onFocusAndBlurGameTime}
            onChangeFormation={this.onChangeFormation}
          />
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mt-3">
          <Button
            label="Return to Home"
            width="100%"
            onClick={this.onClickGoHome}
          />
        </div>
        <ConfirmDialog show={stopModalVisible} width={"500px"}>
          <div className="block text-center w-full">
            <span className="font-semibold">
              Would you like to exit this match?
            </span>
          </div>
          <div className="flex mt-6 w-full">
            <Button
              label="Save & Exit Match"
              width="40%"
              backgroundColor="green"
              noBorder
              margin="0.25rem"
              onClick={this.onClickSaveAndExit}
            />
            <span className="px-2" />
            <Button
              label="Delete & Exit Match"
              width="40%"
              backgroundColor="#f00"
              noBorder
              margin="0.25rem"
              onClick={this.onClickExit}
            />
            <span className="px-2" />
            <Button
              label="Resume Game"
              width="40%"
              backgroundColor="#0f0"
              noBorder
              margin="0.25rem"
              onClick={this.onClickStopModal}
            />
          </div>
        </ConfirmDialog>
      </div>
    )
  }
}

GameIn.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  game: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  selectTeam: PropTypes.func.isRequired,
  selectGame: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user,
    game: state.game
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    selectTeam,
    selectGame
  }
)(GameIn)