import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import firebase from 'firebase'
import styles from "./styles"
import Spinner from "../components/spinner/spinner"
import NavHeader from "../components/navheader/navheader"
import {
  serviceRequest,
  currentUser,
  selectTeam,
  getAllTeams
} from '../../actions/User'
import { progressGames, selectGame } from "../../actions/Game"
import { getCurrentUTCSeconds, formatTime } from "../utils/TimerUtils"

const itemClass =
  "bg-gray-500 w-full h-16 sm:h-16 md:h-20 lg:h-24 rounded overflow-hidden shadow-lg flex cursor-pointer hover:bg-green-500"
const itemFont = "text-xs sm:text-md md:text-lg lg:text-xl xl:text-2xl font-bold"

class MainApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fbLoading: false,
      teams: [],
      finishedGames: [],
      progressGames: [],
      mostRemainTeamName: '',
      mostGame: null,
      mostTeam: null
    }
    this.onClickCreateTeam = this.onClickCreateTeam.bind(this)
    this.onClickManageOneTeam = this.onClickManageOneTeam.bind(this)
    this.onClickViewTeams = this.onClickViewTeams.bind(this)
    this.onClickRateApp = this.onClickRateApp.bind(this)
    this.onClickManageAllTeams = this.onClickManageAllTeams.bind(this)
  }

  componentDidMount() {
    const { serviceRequest, user } = this.props;
    const body = JSON.stringify({
      email: user.email
    })
    
    serviceRequest('user/currentSubsUser', 'POST', body, false, this.requestSuccess.bind(this), this.requestFail.bind(this));
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  startTimer = () => {  
    this.timer = setInterval(() => {
      const { mostGame } = this.state
      const gameDb = firebase.firestore()
        gameDb.collection("games").doc(`game-${mostGame.id}`).get()
          .then(doc => {
            if (doc.exists) {
              const firData = doc.data()
              const gameTime = getCurrentUTCSeconds() - firData.startTime
              const remainTime = firData.isPlaying ? firData.gameDuration - gameTime : firData.startTime === 0 ? 0 : (firData.gameDuration - (firData.pauseTime - firData.startTime))
              
              this.setState({
                remainTime: formatTime(remainTime)
              })
            } else {
              if (this.timer) {
                clearInterval(this.timer)
                this.setState({
                  mostRemainTeamName: '',
                  remainTime: ''
                });
              }
            }
          })
          .catch(error => {
            console.log('---Firebase doc get error: ', error)
          })
    }, 1000);
  }

  requestSuccess = async (data) => {
    if (!data.error){
      const { currentUser, getAllTeams, progressGames } = this.props;
      currentUser(data.user);
      if (data.teams) {
        data.teams.sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at)
        })
      }
      getAllTeams(data.teams)
    
      this.setState({
        teams: data.teams,
        finishedGames: data.finished_games
      })
      progressGames(data.progress_games)
      if (data.progress_games.length > 0) {
        const fbGames = []
        const fArray = data.progress_games.map(async (game) => {
          const gameDb = firebase.firestore()
          await gameDb.collection("games").doc(`game-${game.id}`).get()
            .then(doc => {
              if (doc.exists) {
                const firData = doc.data()
                const gameTime = getCurrentUTCSeconds() - firData.startTime
                const status = {
                  gameId: firData.gameId,
                  remainTime: firData.gameDuration - gameTime
                }
                fbGames.push(status)
              }
            })
            .catch(error => {
              console.log('---Firebase doc get error: ', error)
            })
        })
        this.setState({
          fbLoading: true
        })
        
        await Promise.all(fArray)
        this.setState({
          fbLoading: false
        }, () => {
          fbGames.sort((a, b) => b.remainTime - a.remainTime)
          if (fbGames.length > 0) {
            const findGameId = fbGames[0].gameId
            const mostGame = data.progress_games.find(item => item.id === findGameId)
            if (mostGame) {
              const mostTeam = data.teams.find(team => team.id === parseInt(mostGame.main_team_id))
              if (mostTeam) {
                this.setState({
                  mostRemainTeamName: mostTeam.team_name,
                  mostGame,
                  mostTeam
                }, () => {
                  this.startTimer()
                })
              }
            }
          }
        })
      } else {
        if (this.timer) {
          clearInterval(this.timer)
        }
        this.setState({
          mostRemainTeamName: '',
          remainTime: ''
        })
      }
    }
  }

  requestFail = (error) => {
    console.log('--------: ', error)
  }

  onClickCreateTeam() {
    this.props.history.push({
      pathname: "create-team",
      state: {
        teamId: 0
      }
    })
  }

  onClickManageAllTeams() {
    this.props.history.push("manage-subs")
  }

  onClickManageOneTeam() {
    const { mostGame, mostTeam } = this.state
    const { selectTeam, selectGame } = this.props
    selectGame(mostGame)
    selectTeam(mostTeam)
    this.props.history.push("game-in")
  }

  onClickViewTeams() {
    this.props.history.push("view-allteam")
  }

  onClickRateApp() {
    this.props.history.push("rate-app")
  }

  render() {
    const { user } = this.props
    const { loading } = user

    if (!user.regToken || user.regToken && user.userInfo && !user.userInfo.teams_type) {
      return <Redirect to="/login" />
    }

    const { teams, finishedGames, fbLoading, mostRemainTeamName, remainTime } = this.state
    if (loading || fbLoading) {
      return <Spinner show={loading || fbLoading} />
    }

    return (
      <div className="full-image-bg relative">
        <NavHeader history={this.props.history}/>
        <div className="w-full flex justify-center px-2 absolute" style={styles.container}>
          <div className="flex flex-wrap mx-2 max-w-lg xl:max-w-4xl lg:max-w-3xl md:max-w-2xl sm:max-w-lg">
            {mostRemainTeamName !== '' && (
              <div className="w-full px-2 mb-4">
                <div
                  className={itemClass}
                  style={styles.item}
                  onClick={this.onClickManageOneTeam}
                >
                  <span className={itemFont}>{`Manage Subs for ${mostRemainTeamName} ${remainTime ? remainTime : ''}`}</span>
                </div>
              </div>
            )}
            {teams.length > 0 && (
              <div className="w-full px-2 mb-4">
                <div
                  className={itemClass}
                  style={styles.item}
                  onClick={this.onClickManageAllTeams}
                >
                  <span className={itemFont}>Manage Subs (All Teams)</span>
                </div>
              </div>
            )}
            {teams.length > 0 && (
              <div className="w-full px-2 mb-4">
                <div
                  className={itemClass}
                  style={styles.item}
                  onClick={this.onClickViewTeams}
                >
                  <span className={itemFont}>Edit Team Roster</span>
                </div>
              </div>
            )}
            <div className="w-full px-2 mb-4">
              <div
                className={itemClass}
                style={styles.item}
                onClick={this.onClickCreateTeam}
              >
                <span className={itemFont}>Create New Team</span>
              </div>
            </div>
            {finishedGames.length > 0 && (
              <div className="w-full px-2 mb-4">
                <div
                  className={itemClass}
                  style={styles.item}
                  onClick={this.onClickRateApp}
                >
                  <span className={itemFont}>Rate the Subs App</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

MainApp.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  currentUser: PropTypes.func.isRequired,
  selectTeam: PropTypes.func.isRequired,
  getAllTeams: PropTypes.func.isRequired,
  progressGames: PropTypes.func.isRequired,
  selectGame: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps, 
  { 
    serviceRequest,
    currentUser,
    selectTeam,
    getAllTeams,
    progressGames,
    selectGame
  }
)(MainApp)

