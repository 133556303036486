import React from "react"
import PropTypes from "prop-types"

export default class DropDown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { label, name, className, options, selectedIndex, error, placeholder, onChange, onBlur } = this.props
    return (
      <div className={className}>
        {label && (
          <label className="block tracking-wide text-grey-darker text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-semibold mb-2">
            {label}
          </label>
        )}
        <div className="relative">
          <select 
            name={name}
            className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl py-2 px-4 pr-8 rounded"
            onChange={onChange}
            onBlur={onBlur}
          >
            <option disabled="disabled" selected >{placeholder}</option>
            {options.map((i, index) => (
              <option key={`index-${index+1}`} value={index} selected={selectedIndex === index}>{i}</option>
            ))}
          </select>
          <div
            className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker"
            style={{ top: 13, right: 0 }}
          >
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        {!!error && <p className="w-full block text-left text-red-500 text-xs">{error}</p>}
      </div>
    )
  }
}

DropDown.defaultProps = {
  onChange: null,
  onBlur: null,
  name: '',
  placeholder: '',
  error: '',
  selectedIndex: -1
}

DropDown.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.instanceOf(Array).isRequired,
  selectedIndex: PropTypes.number,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}
