const styles = {
  container: {
    width: "100%",
    paddingTop: "20vh",
    minHeight: "100vh",
    height: "auto !important",
  },
}

export default styles
