import React from "react"
import PropTypes from "prop-types"

export default class Spinner extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { show } = this.props
    if (!show) {
      return null
    }
    return (
      <div className="fixed flex top-0 left-0 right-0 bottom-0 modal-bg-color p-2">
        <div className="max-w-sm spinner md:spinner-md xs:spinner-xs rounded-lg m-auto p-2" />
      </div>
    )
  }
}

Spinner.propTypes = {
  show: PropTypes.bool.isRequired,
}
