import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest, setFeedback } from "../../../actions/User"

class UnFullRated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      txtFeedback: ''
    }
    this.goHome = this.goHome.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
  }

  componentWillUnmount() {
    const { setFeedback } = this.props
    setFeedback(null)
  }

  goHome = () => {
    const { setFeedback, history } = this.props
    setFeedback(null)
    history.push("/")
  }

  onChange = (e) => {
    const { target } = e
    const { value } = target
    this.setState({
      txtFeedback: value
    })
  }

  onClickSubmit = () => {
    const { txtFeedback } = this.state
    const { user, serviceRequest } = this.props
    const { feedback } = user
    const body = JSON.stringify({
      feedbackId: feedback.id,
      comment: txtFeedback
    })
    serviceRequest('feedback/addComment', 'POST', body, false, this.feedbackSuccess.bind(this));
  }

  feedbackSuccess = (data) => {
    if (data.feedback) {
      const { setFeedback, history } = this.props
      setFeedback(null)
      history.push("/")
    }
  }

  render() {
    const { user } = this.props

    if (!user.regToken) {
      return <Redirect to="/login" />
    }

    if (!user.feedback) {
      return <Redirect to="/" />
    }

    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <span className="w-full text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            Please share with us any additional feedback on how we can improve this
            app in the future.
          </span>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg border">
          <textarea 
            className="w-full text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl" name="Text1" cols="40" rows="5"
            onChange={this.onChange}
          />
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mb-4 bg-green rounded-lg">
          <span className="w-full text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            We&apos;ll review and update as appropriate. Thank you!
          </span>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mt-10 bg-green rounded-lg">
          <Button 
            label="Submit"
            width="40%"
            noBorder
            backgroundColor="#ffdf71"
            onClick={this.onClickSubmit}
          />
          <Button
            label="Return to Home"
            width="40%"
            noBorder
            backgroundColor="#ffdf71"
            onClick={this.goHome}
          />
        </div>
      </div>
    )
  }
}

UnFullRated.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  setFeedback: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    setFeedback
  }
)(UnFullRated)