const formObj = {
  teamName: "",
  season: "",
  teamGender: "",
  avgAge: "",
}

const rowObj = {
  athleteName: "",
  jerseyNumber: "",
  postion: "",
}

export const stateObj = {
  formControls: formObj,
  rowControls: rowObj,
  formErrors: formObj,
  formInvalid: false,
}
