const styles = {
  container: {
    width: "100%",
    paddingTop: "2vh",
    maxHeight: "100vh",
    height: "auto !important"
  }
}

export default styles
