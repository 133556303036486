import * as ActionTypes from '../config/ActionTypes'

export const selectGame = (team) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.SELECT_GAME,
            payload: team
        })
    }
}

export const progressGames = (games) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.PROGRESS_GAMES,
            payload: games
        })
    }
}