import React, { Component } from "react"
import PropTypes from "prop-types"
import { Droppable } from "react-beautiful-dnd"

import Item from "./item"

const getListStyle = (isDraggingOver) => ({
  border: isDraggingOver && "1px solid lightblue",
  overflow: 'hidden'
})

class OneColumn extends Component {
  static propTypes = {
    droppableId: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired
  }

  render() {
    const { droppableId, data, ...props } = this.props
    return (
      <Droppable droppableId={droppableId} direction="horizontal">
        {(provided, snapshot) => {
          return (
            <div
              className={`flex items-center justify-around h-full overflow-hidden ${droppableId === 'posGK' ? 'w-12' : 'w-full'}`}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {data.map((item, index) => {
                return (
                  <Item
                    item={item}
                    index={index}
                    key={item.id}
                    {...props}
                    isDraggingOver={snapshot.isDraggingOver}
                  />
                )
              })}
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    )
  }
}

export default OneColumn
