import React from "react";

class Terms extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="max-w-2xl mx-auto p-2 mt-20 text-start">
        <div className="w-full h-full overflow-y-scroll">
        <p className="p1"><span className="s1"><strong>Terms of Service</strong></span></p>
        <p className="p2">&nbsp;</p>
        <p className="p3"><strong>Please Read These Terms Carefully Before Using this Service</strong></p>
        <p className="p4">&nbsp;</p>
        <p className="p4">&nbsp;</p>
        <p className="p5">The contents on this <strong>&ldquo;</strong><span className="s2"><strong>riserunsports.com&rdquo;</strong></span> website and "<strong>Soccer Stats Tracker</strong>" app (the "<strong>Site&rdquo; and &ldquo;app&rdquo; or &ldquo;mobile app</strong>") are provided by <span className="s2">Rise Run Sports LLC, 3838 Bordeaux Dr, Northbrook, IL 60062 DUNS 029367188 (&ldquo;<strong>RRS</strong>&rdquo;), </span>as a service to its customers comprising coaches, athletes, parents, teams (hereinafter referred to as &ldquo;you&rdquo; &ldquo;User&rdquo;). <strong>By using this site and/or mobile app, you agree to these terms. If you do not agree to them, do not use the site or access the service from it.</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><strong>Introduction</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p7">&ldquo;RRS&rdquo; is a sports app with different features.</p>
        <p className="p8">&nbsp;</p>
        <ul className="ul1">
        <li className="li5"><strong>Acceptance of Terms </strong></li>
        </ul>
        <p className="p6">&nbsp;</p>
        <p className="p5">This Service is offered to you conditioned on your acceptance without modification, of the Terms, conditions, and notices contained in this agreement and any operating rules, policies, and procedures that are published on this Site/app by <span className="s2">Rise Run Sports LLC</span> (collectively, the "Terms"). The following Terms govern your access to and use of the Service. Your use of the Service constitutes your agreement to all such Terms. These Terms may be updated by us from time to time without notice to you. These Terms of Use apply to all users of the app, including visitors on the site and users who are also contributors of content, information and other materials on the app.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">The words "use" or "using" in this Agreement, means any time a User, directly or indirectly, with or without the aid of a machine or device, does or attempts to access, interact with, use, display, view, print or copy from <span className="s2">Site/app</span> Services, transmit, receive or exchange data or communicate with the <span className="s2">RRS</span> Services, or in any way utilizes, benefits, takes advantage of or interacts with any function, service or feature of the app Services, for any purpose whatsoever.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">Unless explicitly stated otherwise, these Terms will govern the use of any new features that augment or enhance the current Services, including the release of new resources and services.</p>
        <p className="p6">&nbsp;</p>
        <ul className="ul1">
        <li className="li5"><strong>The Service </strong></li>
        </ul>
        <p className="p6">&nbsp;</p>
        <p className="p7">The Site and mobile app help coaches, athletes, parents and teams to collect, manage and use data related to sports performance. <span className="s2">The app allows Users to upload their personal and sports performance details. </span></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">App Services are hosted using servers in USA.</p>
        <p className="p6">&nbsp;</p>
        <p className="p7">The app is free to use. Although we do not charge for either signing up or for using the services, your internet service provider may collect fee for its services.</p>
        <p className="p8">&nbsp;</p>
        <p className="p5">You also understand and agree that the Services may include advertisements that promote other products and services, and that these advertisements are necessary for <span className="s2">RRS</span> to provide the Services. You also understand and agree that the Services may include certain communications f<span className="s2">rom RRS</span> such as service announcements, administrative messages and updates.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><strong>3. <span className="Apple-converted-space">&nbsp; </span>Modifications to the Service</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">RRS reserves the right to modify or discontinue all or part of the Service, temporarily or permanently, with or without notice to User, and is not obligated to support or update the Service. The amended Terms of Service shall be effective immediately after they are initially posted on the Site/app. User's continued use of the Service after the posting of the amended Terms on the Site/app constitute User's affirmative: (a) acknowledgement of the Terms and its modifications; and (b) agreement to abide and be bound by the Terms, as amended. User acknowledges and agrees that <span className="s2">RRS</span> shall not be liable to User or any third party in the event that <span className="s2">RRS</span> <span className="s2">exercises </span>its right to modify or discontinue all or part of the Service. You are responsible for being familiar with any changes or modifications to these Terms of Use. You can review the most current version of the Terms at any time at: <span className="s2">www.riserunsports.com/terms</span></p>
        <p className="p8">&nbsp;</p>
        <p className="p5"><strong>4. <span className="Apple-converted-space">&nbsp; </span>Registration</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p9">In order to access the app services, Users will be required to register/sign up. However, Site visitors can read articles on the Site without registering.</p>
        <p className="p10">&nbsp;</p>
        <p className="p5">By electing to use these services; (a) you warrant that all information you have submitted for registering is current, true and accurate; (b) you agree to update this information; (c) You will not create more than one personal profile: (d) If we disable your account, you will not create another one without our permission; (e).you agree not to use the Services for any unlawful purpose.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">You agree to notify RRS immediately if you suspect any unauthorized use of your account. You are solely responsible for any and all use of your account by any third party.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">You agree that you are aware about the charges for using this service through mobile/ internet gateway and will not raise any dispute in this regard with RRS<span className="s3">.</span>&nbsp;</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">You also represent that you are not a person barred from receiving the Services under the laws of any applicable jurisdiction. If you provide any information that is untrue, inaccurate, not current or incomplete, or if RRS has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete,<span className="s2"> RRS</span> has the right to suspend or terminate your access to the app and refuse any and all current or future use of the Services (or any portion thereof).</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><strong>5.<span className="Apple-converted-space">&nbsp; </span>Privacy Policy</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">We respect your privacy and the use and protection of your Personal Information. RRS collects certain personal information while providing the Service. Such information and data collected by us about Users will be treated as confidential and protected and used by us in accordance with GDPR incorporated into our Privacy Policy. You can review the most current version of our Privacy Policy at any time at: <span className="s2">www.riserunsports.com/privacy</span>. HOWEVER, ALL CONTENT UPLOADED BY YOU IS PUBLICLY VISIBLE. THERE ARE NO PRIVACY SETTINGS FOR THE CONTENT UPLOADED BY YOU. HENCE, TAKE CARE TO UPLOAD ONLY SUCH CONTENT WHICH YOU FEEL CAN BE DISPLAYED PUBLICLY.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><strong>6.<span className="Apple-converted-space">&nbsp; </span>User Submissions </strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p11">As a registered <span className="s4">User,</span> you may upload your profile including personal details to our website (collectively referred to as "User Submissions"). As a user, you understand and accept that you are solely responsible for your own User Submissions and the consequences of posting or publishing them. <span className="s4">RRS </span>does not guarantee any confidentiality with respect to any User submissions despite it not being published.</p>
        <p className="p11">You affirm, represent, and/or warrant that you own or have the necessary licenses, rights, written consents, release and/or permissions of every identifiable individual to use the submissions made by you and authorize RRS to use all patent, trademark, trade secret, copyright or other proprietary rights in and to any and all User Submissions to enable inclusion and use of the User Submissions in the manner contemplated by <span className="s5">Rise Run Sports LLC</span> and these Terms of Service and will not cause injury to any person or entity.</p>
        <p className="p9">By posting User Submissions, you hereby grant <span className="s6">RRS </span>(and its successors) a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, fully sub-licensable (including any moral right) and transferable license to use, adapt, publish, translate, reproduce, distribute, communicate to the public, perform and display the contents of such Submissions (in whole or in part), and/or incorporate it in other works<span className="Apple-converted-space">&nbsp; </span>throughout the world in any form, media, or technology now known or later developed, for the full term of any rights that may exist in such content. You grant <span className="s6">RRS </span>the right to use the name that you submit in connection with such Submissions, if we choose.</p>
        <p className="p12">&nbsp;</p>
        <p className="p13">You also hereby grant <span className="s2">RRS </span>the right to use User Submissions for promoting the <span className="s4">RRS </span>(and derivative works thereof) through any media channels. You also hereby grant RRS and each user of <span className="s4">RRS </span>a non-exclusive license to access your User Submissions through <span className="s4">the Site</span> and to use, reproduce, distribute and display such User Submissions on social networks. You understand that these functionalities may include downloading your User Submission to a computer, mobile phone or other device. The above licenses granted by you terminate within a commercially reasonable time after you remove or delete your User Submissions from <span className="s4">the Site.</span></p>
        <p className="p8">&nbsp;</p>
        <p className="p5"><span className="s2">RRS </span>reserves the right to decide whether a User Submission is appropriate. <span className="s2">RRS </span>may advise User to remove such material immediately or may at any time, without prior notice and at its sole discretion remove such User Submissions and/or terminate User's access for uploading such material which is in violation of these TERMS. You acknowledge that <span className="s2">RRS </span>may preserve your Submissions and may also disclose them if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with a legal process, (b) enforce the Terms of Service, or (c) respond to any claims that the content of any Submission violates the rights of third parties.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">Without limiting the foregoing, <span className="s2">RRS </span>reserves the right to use Submissions as it deems appropriate, including, without limitation, deleting, editing, modifying, rejecting, or refusing to post it. <span className="s2">RRS </span>is under no obligation to offer you any payment for Submissions or the opportunity to edit, delete or otherwise modify Content once it has been submitted to <span className="s2">RRS</span>. <span className="s2">RRS</span> shall have no duty to attribute authorship of Submissions to you, and shall not be obligated to enforce any form of attribution by third parties.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><span className="s2">RRS</span> does not endorse any Submissions that may appear on or through the Site and makes no warranties, express or implied, as to their accuracy and reliability. <span className="s2">RRS </span>expressly disclaims any and all liability in connection with any and all Submissions.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><strong>7. <span className="Apple-converted-space">&nbsp; </span>Links&nbsp;</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">RRS provides links to other websites. RRS may share your contact information with those websites.<span className="Apple-converted-space">&nbsp; </span>Your use of each of those websites is subject to the conditions, if any, that each of those websites has posted. You agree that we are not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the contents, products, services or any transmissions received through such websites.</p>
        <p className="p6">&nbsp;</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><strong>8. <span className="Apple-converted-space">&nbsp; </span>User Conduct/Lawful Use</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">User agrees that use of the app is subject to all applicable International, National, Federal, state, and local laws and regulations. The User agrees to abide by these laws and is solely responsible for the content of its communications through the Service. User agrees to use<span className="s2"> the app</span> for lawful purposes only.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">You agree:</p>
        <ul className="ul1">
        <li className="li5">to comply with all applicable laws regarding the transmission of technical data exported through <span className="s2">the app.</span></li>
        <li className="li5">not to <span className="s2">use the app</span> for illegal purposes;</li>
        <li className="li5">not to interfere or disrupt networks connected to <span className="s2">the app.</span></li>
        <li className="li5">to comply with all regulations, policies and procedures of networks connected to <span className="s2">the app;</span></li>
        <li className="li5">not to resell or transfer your access to the app/service to any third party;</li>
        <li className="li5">not to restrict any other visitor from using the service;</li>
        <li className="li5">to act responsibly, treat other app users with respect and not violate their rights, and not harm them emotionally or physically;</li>
        <li className="li11"><span className="s4">not to </span>modify, adapt, sublicense, translate, sell, reverse engineer, decompile, or disassemble any <span className="Apple-converted-space">&nbsp; </span>portion of the Service or any part of the app;</li>
        <li className="li11">not to harvest or collect information about Users of the service without their express consent;</li>
        <li className="li5"><span className="s7">not to </span>solicit personal information from anyone under the age of 18;</li>
        <li className="li11"><span className="s4">not to upload contents that </span>contain restricted or password only access pages or hidden pages (those not linked to or from another accessible page);</li>
        <li className="li11">not to display unauthorized commercial advertisement on your profile.</li>
        </ul>
        <p className="p14">&nbsp;</p>
        <p className="p5">Please verify that all information submitted is accurate and factual.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">User agrees that - it shall not distribute or facilitate distribution of any content, including but not limited to videos, images, sounds that: (1) is unlawful, threatening, abusive, harassing, defamatory, libellous, deceptive, fraudulent, invasive of another's privacy, tortuous, contains explicit or graphic descriptions or accounts of sexual acts (including but not limited to sexual language of a violent or threatening nature directed at another individual or group of individuals) or provide links to adult contents elsewhere, or otherwise <span className="s2">violates RRS's</span> rules or policies; (2) victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability; (3) infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party; (4) constitutes unauthorized or unsolicited advertising; (5) impersonates any person or entity, including any of our employees or representatives.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><span className="s2">RRS</span> does not monitor the contents uploaded by the users and depend on users emailing us the content that breaches terms of service. However, if you are found to be involved in any of the afore-mentioned acts of using the service unlawfully, or if it is brought to our notice by another user or the copyright owner, you will be advised to remove the materials immediately. Failure to immediately remove the content will result in a cancellation of your account. <span className="s2">RRS</span> also reserves the right to remove such prohibited material without warning or notification to the User.</p>
        <p className="p6">&nbsp;</p>
        <p className="p9">If you encounter such prohibited material on the Site you waive your right to any damages related to such exposure. Such material should be immediately reported to <span className="s2">info@riserunsports.com</span></p>
        <p className="p15">&nbsp;</p>
        <p className="p9">In case of any violation of these Terms, <span className="s6">RRS </span>reserves the right to seek all remedies available by law and in equity for such violations.</p>
        <p className="p10">&nbsp;</p>
        <p className="p5"><strong>9. <span className="Apple-converted-space">&nbsp; </span>Limitation of Liability</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">As a condition of use of the app, and in consideration of the services provided by <span className="s2">RRS,</span> User agrees that neither <span className="s2">RRS,</span> nor any officer, affiliate, director, shareholder, agent, contractor or employee of <span className="s2">RRS</span> will be liable to User or any third party for any direct, indirect, incidental, special, punitive, or consequential loss of profits, loss of earnings, loss of business opportunities, damages, expenses, or costs resulting directly or indirectly from, or otherwise arising in connection with the RRS Service or Content.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR APP/SERVICE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR OTHER CONFIDENTIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR APP/SITE, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR APP/SITE BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA RRS SITE/APP, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">You specifically acknowledge that<span className="s2"> Rise Run Sports LLC </span>shall not be liable for user submissions or the defamatory, offensive, or illegal conduct of any third party resulting in any physical or emotional ill-effects, and that the risk of harm or damage from the foregoing rests entirely with you.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">RRS is controlled and offered <span className="s2">by Rise Run Sports LLC</span> using servers in USA. <span className="s2">RRS makes no representations that the RRS Service is appropriate or available for use in other lo</span>cations. Those who access or use <span className="s2">RRS Site/app</span> from other jurisdictions do so at their own volition and are responsible for compliance with local law.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><span className="s2"><strong>RRS service is provided free of any charge. Consequently, RRS </strong></span><strong>shall not be liable for any damages, resulting from the use or the inability to use </strong><span className="s2"><strong>RRS</strong></span><strong> services or for cost of procurement of substitute goods and services or resulting from any services obtained through RRS or resulting from unauthorized access to, deletion or alteration of user's transmissions or data or failure of the app to store user's transmissions or data, including but not limited to, damages for loss of profits, use, data or other intangible property, even if </strong><span className="s2"><strong>RRS </strong></span><strong>has been advised of the possibility of such damages. </strong></p>
        <p className="p5"><strong>Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages under certain circumstances; consequently, some of the above limitations may not apply to user, in whole or in part.</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p11">Your <span className="s5">RRS </span>account is non-transferable and any rights to your <span className="s5">RRS</span> ID or contents within your account terminate upon receiving a formal request.<span className="Apple-converted-space">&nbsp; </span>Consequently, your account will be terminated and all contents therein permanently deleted.</p>
        <p className="p14">&nbsp;</p>
        <p className="p5"><strong>10. License</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">The materials on RRS Site and app are copyrighted and any unauthorized use of these materials may violate copyright, trademark, and other laws. This is a license, not a transfer of title, and is subject to the following restrictions: you may not:</p>
        <ul className="ul1">
        <li className="li16">Modify the Materials or use them for any commercial purpose, or any public display, performance, sale or rental;</li>
        <li className="li16">Decompile, reverse engineer, or disassemble software Materials except and only to the extent permitted by applicable law;</li>
        <li className="li16">Remove any copyright or other proprietary notices from the Materials;</li>
        <li className="li16">You agree to prevent any unauthorized copying of the Materials.</li>
        </ul>
        <p className="p6">&nbsp;</p>
        <p className="p5"><strong>11. <span className="Apple-converted-space">&nbsp; </span>Disclaimer/No Warranty</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><span className="s7">RRS service is </span>provided on an "as is" basis without any express or implied warranty of any kind including warranties of merchantability, non-infringement of intellectual property, or fitness for any particular purpose. In no event shall<span className="s2"> Rise Run Sports LLC</span> be liable for any damages whatsoever (including, without limitation, damages for loss of profits, business interruption, loss of information) arising out of the use of or inability to use the Site/app; any unauthorized access to or use of our secure servers and/or; any and all personal information and/or financial information stored therein; any bugs, viruses, trojan horses, or the like which may be transmitted to or through our app by any third party, and/or; any errors or omissions in any content or for any loss or damage of any kind incurred as a result of the use of any content posted, server transmitted, or otherwise made available via <span className="s2">the app.</span> <span className="s2">Further, RRS</span> is not liable for any statements, representations or content provided by its users in any public forum, personal home page or other interactive area, even if<span className="s2"> RRS</span> has been advised of the possibility of such damages.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><span className="s2">RRS </span>further does not warrant that its service will be secure or uninterrupted; the information, links or other items contained within these materials will be accurate and complete. <span className="s2">RRS </span>may make changes to these materials at any time without notice.<span className="s2"> RRS</span> makes no commitment to update the Materials. No oral advice or written information given by<span className="s2"> RRS,</span> its employees, licensors or the like will create a warranty; nor may you rely on any such information or advice.</p>
        <p className="p6">&nbsp;</p>
        <p className="p11"><span className="s5">RRS </span>is not responsible for any problems or technical malfunction of any phone network or lines, computer online systems, servers or providers, computer equipment, software, failure of any players due to technical problems or traffic congestion on the Internet or on any of the <span className="s4">RRS </span>Services or combination thereof, including any injury or damage to Users or to any person's computer related to or resulting from using or downloading materials in connection with the <span className="s5">RRS</span> Services.</p>
        <p className="p14">&nbsp;</p>
        <p className="p14">&nbsp;</p>
        <p className="p5"><strong>12. <span className="Apple-converted-space">&nbsp; </span>Indemnification</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">User will defend, indemnify and hold harmless <span className="s2">RRS and</span> any of its affiliates, directors, officers, employees, proprietors, independent contractors, consultants, partners, shareholders, representatives, customers, agents, predecessors, successors, and permitted assigns from and against any claim, suit, demand, loss, damage, expense (including reasonable attorneys' fees and costs) or liability that may result from, arise out of or relate to: (a) acts or omissions by User arising out of or in connection with this Agreement; (b) intentional or negligent violations by User of any applicable laws or governmental regulation, (c) contractual relations between the User and a third party; or (d) infringement of intellectual property rights including, but not limited to, rights relating to patent and copyright.&nbsp;</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">User acknowledges that the content uploaded by it, is entirely generated by the User. RRS has no control over the content or information transmitted by User and that<span className="s2"> RRS</span> does not examine the use to which User put the Service or the nature of the information User sends or receives. User hereby indemnifies and holds harmless RRS, its stockholders, officers, directors, employees and agents from any and all loss, cost, damage, expense, or liability relating to or arising out of the transmission, reception, and/or content of information of whatever nature transmitted or received by User or User's users. This indemnification obligation will survive these Terms of Service and your use of the app.</p>
        <p className="p6">&nbsp;</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><strong>13. <span className="Apple-converted-space">&nbsp; </span>Intellectual Property Rights</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">When you provide Submissions to <span className="s2">RRS</span>, you still retain all of your rights of ownership in your Submissions.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">User acknowledges that content, including but not limited to policy information, text, software, music, sound, images, video, graphics, meta data, geographic information, the arrangement of text and images, commercially produced information, and other material contained on the app or used through the Service ("Content"), is protected by copyright, trademarks, service marks, patents or other proprietary agreements and laws and User is only permitted to use the Content as expressly authorized by <span className="s2">RRS.</span> These Terms do not transfer any right, title, or interest in the Service, app or the Content to User, and User may not copy, reproduce, distribute, or create derivative works from this Content without express authorization by <span className="s2">RRS.</span> User agrees not to use or divulge to others any information designated by <span className="s2">RRS </span>as proprietary or confidential. Any unauthorized use of any Content contained on the Site/app or through the Service may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes; and may result in termination of the User&rsquo;s access to the service. Except as specifically permitted herein, no portion of the information on Site/app may be reproduced in any form, or by any means, without prior written permission from <span className="s2">RRS </span>or the specific content&rsquo;s owner. Users are not permitted to modify, distribute, publish, transmit or create derivative works of any material found on the Site for any public, personal or commercial purposes.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">If you download, print or display any<span className="s2"> RRS</span> <span className="s2">Site/app </span>Content for personal use, you must retain and you shall not cover or obscure, all copyright and other proprietary notices. You may not use any metatags or other &ldquo;hidden text&rdquo; utilizing th<span className="s2">e RRS </span>name or trademarks or service marks without our express written consent. Any unauthorized use terminates the permission or license granted <span className="s2">by RRS.</span></p>
        <p className="p8">&nbsp;</p>
        <p className="p5">Through proper notification,<span className="s2"> RRS</span> will remove Submissions that infringe upon the intellectual property rights of others and reserves the right to remove Submissions without prior notice<span className="s2">. RRS</span> assumes no responsibility for monitoring the Site. In the event it chooses to do so at its discretion,<span className="s2"> RRS</span> assumes no responsibility for the Submissions or any obligation to remove any inappropriate Submissions or any responsibility for the conduct of a User submitting inappropriate Submissions. At its sole discretion, RRS may remove any Submission and/or terminate User&rsquo;s account for violating any of these Terms of Service at any time and without notice.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><span className="s2">RRS reserves</span> the right, but not the obligation, to take any appropriate legal action for any illegal or unauthorized use of RRS Site/app or violation of the Terms of Service.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><strong>14. <span className="Apple-converted-space">&nbsp; </span>User Disputes </strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p11"><span className="s5">RRS</span> reserves the right, but has no obligation, to resolve or arbitrate disputes between you and other Users.</p>
        <p className="p14">&nbsp;</p>
        <p className="p13"><span className="s5">RRS</span> Services are provided free of any charge. You agree to use the Service at your own risk and responsibility. Hence, <span className="s5">RRS</span> will not be liable to pay any claim. However, under any exceptional circumstance, you agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the <span className="s5">RRS </span>Services<span className="Apple-converted-space">&nbsp; </span>will be permanently barred if such claim is not filed within one (1) year after such claim or cause of action arose.</p>
        <p className="p12">&nbsp;</p>
        <p className="p5"><strong>15. <span className="Apple-converted-space">&nbsp; </span>Termination</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">You agree that we may terminate your right to use our Services if the information that you provided initially to sign up for our Services or subsequently modified, contains false or misleading information, or conceals or omits any information we would likely consider material. Additionally<span className="s2">, RRS</span> has the right to deny any Service.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5">Any attempt to undermine or cause harm to <span className="s2">RRS </span>server or its customers, is strictly prohibited and will be subject to automatic account termination. This includes Spamming, <span className="s10">t</span>ransmission of - malware, viruses, trojan horses; or by linking to sites and files that contain or distribute malware, viruses and trojan horses.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><span className="s2">RRS </span>may terminate User&rsquo;s account without prior notice, if you are in breach of the terms of this Agreement<span className="s2">. RRS</span> will be the sole arbiter as to what constitutes a violation of the Agreement.</p>
        <p className="p6">&nbsp;</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><strong>16. <span className="Apple-converted-space">&nbsp; </span>Entire Agreement/Severability</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">This Agreement sets forth the entire agreement between you and<span className="s2"> RRS</span> in respect of this Site/app and its contents, and supersedes all prior agreements, whether written or oral, with respect to the subject matter hereof. In the event that any provision of the Terms shall, in whole or in part, be determined to be invalid, unenforceable or void for any reason, such determination shall affect only the portion of such provision determined to be invalid, unenforceable or void, and shall not affect in any way the remainder of such provision or any other provision of the Terms. <span className="s2">RRS&rsquo;s</span> failure to act with respect to a breach by User or others does not waive its right to act with respect to subsequent or similar breaches. This Agreement shall inure to the benefit of and be binding upon each of us and our respective successors and permitted assigns.</p>
        <p className="p5"><strong>17.<span className="Apple-converted-space">&nbsp; </span>Ability to Accept Terms of Use</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">You affirm that you are either more than 18 years of age, or you are at least 13 years of age and an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms of Service, and to abide by and comply with these Terms of Service. In any case, you affirm that you are over the age of 13, as the Site, mobile app or any other service provided by us is not intended for children under 13.</p>
        <p className="p6">&nbsp;</p>
        <p className="p5"><strong>18. <span className="Apple-converted-space">&nbsp; </span>Governing Law</strong></p>
        <p className="p6">&nbsp;</p>
        <p className="p5">These Terms shall be governed by and construed in accordance with laws of State of Illinois, USA, without regard to its choice of law provisions. In the event of any conflict between foreign laws, rules and regulations and those of Illinois, the laws, rules and regulations of the Illinois shall govern. The United Nations Convention on Contracts for the International Sale of Goods shall not apply to these Terms. User hereby consents to personal jurisdiction by the courts located in Northbrook, Illinois, USA,<span className="s2"> for resolution of any dispute.</span></p>
        <p className="p17">&nbsp;</p>
        </div>
      </div>
    )
  }
}

export default Terms;