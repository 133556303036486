const styles = {
  container: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  item: {
    justifyContent: "center",
    alignItems: "center",
    padding: "20px"
  }
}

export default styles
