import React from "react"
// import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import Button from "../../components/buttons/button"
import {googlePlayUrl, appleStoreUrl} from "../../../config/Constants"

const googlePlayIcon = require('../../../images/icons/google_play.png')
const appStoreIcon = require('../../../images/icons/apple_icon.png')

export default class FullRated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.goHome = this.goHome.bind(this)
  }

  goHome = () => {
    this.props.history.push("/")
  }

  render() {
    return (
      <div style={styles.container}>
        <div className="max-w-md mx-auto flex p-2 mb-4 bg-green rounded-lg">
          <span className="w-full text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            Thank you! We are glad you enjoy the app. If you have a minute, could you please share your 5 star rating at <a className="underline text-blue-700" href={googlePlayUrl} target="_blank" rel="noopener noreferrer"><img src={googlePlayIcon} className="inline-block cursor-pointer border-0 w-6 h-6 sm:w-6 sm:h-6 xl:w-8 xl:h-8 md:w-8 md:h-8 lg:w-8 lg:h-8" />Google Play</a> or the <a className="underline text-blue-700" href={appleStoreUrl} target="_blank" rel="noopener noreferrer"><img src={appStoreIcon} className="inline-block cursor-pointer border-0 w-6 h-6 sm:w-6 sm:h-6 xl:w-8 xl:h-8 md:w-8 md:h-8 lg:w-8 lg:h-8" />App Store</a>?
          </span>
        </div>
        <div className="max-w-md mx-auto flex p-2 mb-4 bg-green rounded-lg">
          <span className="w-full text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">Thank you for your support!</span>
        </div>

        <div className="max-w-md mx-auto flex p-2 mt-10 bg-green rounded-lg">
          <Button
            label="Return to Home"
            width="60%"
            noBorder
            backgroundColor="#ffdf71"
            onClick={this.goHome}
          />
        </div>
      </div>
    )
  }
}

FullRated.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired
}
