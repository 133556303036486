import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest, selectTeam, setUnVisibleTeams } from "../../../actions/User"
import { selectGame } from "../../../actions/Game"

class ManageSubsAll extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allTeams: []
    }
    this.onClickViewGame = this.onClickViewGame.bind(this)
    this.onClickAddOtherTeam = this.onClickAddOtherTeam.bind(this)
  }

  componentDidMount = async () => {
    const { serviceRequest, user } = this.props;
    const body = JSON.stringify({
      userId: user.userInfo.id,
      getSubs: false
    })
    await serviceRequest('athlete/getAllTeamsAndAthletesByUserId', 'POST', body, false, this.requestSuccess.bind(this));
  }

  requestSuccess = (data) => {
    if (!data.error) {
      data.teams.sort((a, b) => {
        return new Date(b.team.updated_at) - new Date(a.team.updated_at)
      })
      
      this.setState({
        allTeams: data.teams
      })
    }
  }

  onClickViewGame = (team) => {
    const { selectTeam, selectGame } = this.props
    const isProgress = this.isProgressGameTeam(team.id)
    if (isProgress) {
      selectGame(isProgress)
    }
    selectTeam(team)
    this.props.history.push("game-in")
  }

  onClickAddOtherTeam() {
    const { history } = this.props
    history.push("create-team")
  }

  isProgressGameTeam = (teamId) => {
    const { game } = this.props
    
    if (game.progressGames.length > 0) {
      return game.progressGames.find(item => parseInt(item.main_team_id) === teamId)
    } else {
      return false
    }
  }

  render() {
    const { allTeams } = this.state
    const { user } = this.props;
    if (!user.regToken) {
      return <Redirect to="/login" />
    }

    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-base sm:text-sm md:text-md lg:text-lg xl:text-xl">Select Team</span>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <table className="text-left w-full border-collapse bg-white">
            <thead>
              <tr className="text-center">
                <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-base sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border-b border-blue-900">
                  Team
                </th>
                <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-base sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border-b border-blue-900">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {allTeams.map((item, index) => (
                <tr key={`index-${index}`}>
                  <td className="py-2 px-1 border border-blue-900">
                    <span className="font-bold text-base sm:text-sm md:text-md lg:text-lg xl:text-xl">{`${item.team.team_name} (${item.memberCount})`}</span>
                  </td>
                  <td className="border border-blue-900">
                    <Button
                      label={this.isProgressGameTeam(item.team.id) ? "Game In Progress" : "Start Game"}
                      width="100%"
                      backgroundColor={this.isProgressGameTeam(item.team.id) ? "#fc8181" : "#68d391"}
                      noBorder
                      noRounded
                      onClick={() => this.onClickViewGame(item.team)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 py-6 bg-green rounded-lg">
          <Button
            label="Add Another Team"
            width="100%"
            onClick={this.onClickAddOtherTeam}
          />
        </div>
      </div>
    )
  }
}

ManageSubsAll.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  game: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  selectTeam: PropTypes.func.isRequired,
  selectGame: PropTypes.func.isRequired,
  setUnVisibleTeams: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user,
    game: state.game
  };
};

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    selectTeam,
    selectGame,
    setUnVisibleTeams
  }
)(ManageSubsAll)