import React from "react"
// import { connect } from "react-redux"
import PropTypes from "prop-types"


export default class LabelInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      parent,
      label,
      labelmargin,
      type,
      name,
      pattern,
      maxLength,
      value,
      placeholder,
      error,
      disabled,
      onChangeText,
      onBlur
    } = this.props
    return (
      <div className={parent}>
        {!!label && <label className={`block w-full text-left tracking-wide text-grey-darker text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-semibold ${labelmargin}`}>
          {label}
        </label>}
        <input
          className="appearance-none block w-full bg-white border text-grey-darker border text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl py-2 px-4 mb-1"
          name={name}
          value={value}
          type={type}
          pattern={pattern}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={onChangeText}
          disabled={disabled}
          onBlur={onBlur}
        />
        {!!error && <p className="w-full block text-left text-red-500 text-xs">{error}</p>}
      </div>
    )
  }
}

LabelInput.defaultProps = {
  labelmargin: "mb-0",
  pattern: "",
  maxLength: "255",
  onBlur: null
}

LabelInput.propTypes = {
  parent: PropTypes.string,
  label: PropTypes.string,
  labelmargin: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  pattern: PropTypes.string,
  maxLength: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeText: PropTypes.func,
  onBlur: PropTypes.func
}
