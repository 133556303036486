export const formatTime = (time) => {
    let ret = '00 : 00'
    if (time >= 0) {
        const minutes = Math.floor(time / 60) >= 10 ? `${Math.floor(time / 60)}` : `0${Math.floor(time / 60)}`
        const seconds = time % 60 >= 10 ? `${time % 60}` : `0${time % 60}`
        ret = `${minutes} : ${seconds}`
    }
    return ret
}

export const calculatePlayedTimeForAthlete = (athlete, gameTime) => {
    if (athlete.joinTime === -1) {
        return 0
    } else {
        if (athlete.joinTime === 0) {
            if (athlete.outTime === -1) {
                return gameTime
            } else {
                return athlete.outTime
            }
        } else {
            if (athlete.outTime === -1) {
                return gameTime - athlete.joinTime
            } else {
                return athlete.outTime - athlete.joinTime
            }
        }
    }
}

export const getCurrentUTCSeconds = () => {
    const d1 = new Date()
    const d2 = new Date(d1.toISOString())
    return Math.floor(d2.getTime() / 1000)
}