import React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { posOptions } from "../../../config/Constants"

const checkIcon = require("../../../images/icons/check.png")

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    borderRadius          : '10px',
    padding               : '20px'
  }
};

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)';

export default class MultiSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      retValue: ''
    }
  }

  initializeData = () => {
    const { currentPosValue } = this.props;
    const data = [];
    posOptions.map(item => {
      const findValue = currentPosValue.find(value => value === item.abbr);
      const itemValue = {
        ...item,
        selected: findValue !== undefined,
      };
      data.push(itemValue);
    });
    this.setState({
      data,
      retValue: currentPosValue.join(', '),
    });
  };

  onChangeStatus = index => {
    const {data} = this.state;
    data[index].selected = !data[index].selected;
    this.setState(
      {
        data,
      },
      () => {
        const retArr = data.filter(item => item.selected);
        const tmp = retArr.map(item => item.abbr);
        this.setState({
          retValue: tmp ? tmp.join(', ') : '',
        });
      },
    );
  };

  render() {
    const {visible, closeDialog} = this.props;
    const {data} = this.state;
    return (
      <div>
        <Modal
          isOpen={visible}
          onAfterOpen={this.initializeData}
          onRequestClose={() => {
            this.setState(
              {
                data: []
              },
              () => {
                closeDialog('cancel', null, null);
              },
            );
          }}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <div className="w-56 h-full border border-black rounded-lg">
            {data.map((item, index) => (
              <div
                className={`h-10 w-full border-black flex items-center justify-between px-2`}
                style={{borderBottomWidth: index === data.length - 1 ? 0 : 1}}
                key={index}
                onClick={() => this.onChangeStatus(index)}>
                <span className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">{item.name}</span>
                {item.selected && <img src={checkIcon} className="border-0 w-4 h-4"/>}
              </div>
            ))}
          </div>

          <div className="w-56 h-full flex items-center justify-end pt-3">
            <span
              className="text-sm sm:text-base md:text-md lg:text-lg xl:text-xl px-4 py-1 border border-black rounded-lg cursor-pointer"
              onClick={() => {
                this.setState(
                  {
                    data: []
                  },
                  () => {
                    const {retValue} = this.state;
                    closeDialog(null, retValue, null);
                  },
                );
              }}
            >
              Done</span>
          </div>
        </Modal>
      </div>
    )
  }
}

MultiSelect.defaultProps = {
  visible: false,
  currentPosValue: []
}

MultiSelect.propTypes = {
  visible: PropTypes.bool.isRequired,
  currentPosValue: PropTypes.array.isRequired,
  closeDialog: PropTypes.func.isRequired
}