import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import Button from "../../components/buttons/button"
import Spinner from "../../components/spinner/spinner"
import { serviceRequest, setFeedback } from "../../../actions/User"

const unSelectedStar = require("../../../images/icons/star-no-select.png")
const selectedStar = require("../../../images/icons/star-select.png")

const stars = [1, 2, 3, 4, 5]

class RateApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCount: 0,
    }
    this.onRate = this.onRate.bind(this)
    this.submit = this.submit.bind(this)
  }

  onRate = (index) => {
    const { selectedCount } = this.state
    if (selectedCount === 1 && index === 1) {
      this.setState({ selectedCount: 0 })
    } else this.setState({ selectedCount: index })
  }

  feedbackSuccess = (data) => {
    const { setFeedback } = this.props
    if (data.feedback) {
      if (data.feedback.rating === 5) {
        this.props.history.push("/rate-app/full-rated")
      } else {
        setFeedback(data.feedback)
        this.props.history.push("/rate-app/unfull-rated")
      }
    }
  }

  submit = () => {
    const { selectedCount } = this.state
    const { user, serviceRequest } = this.props
    const body = JSON.stringify({
      userId: user.userInfo.id,
      rating: selectedCount,
      appType: 'Subs'
    })
    
    serviceRequest('feedback/create', 'POST', body, false, this.feedbackSuccess.bind(this));
  }

  render() {
    const { selectedCount } = this.state
    const { user } = this.props

    if (!user.regToken) {
      return <Redirect to="/login" />
    }
    return (
      <div style={styles.container}>
        <Spinner show={user.loading} />
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 mb-4 bg-green rounded-lg">
          <span className="font-bold w-full text-center text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
            How would you rate this app, from 1 to 5 stars?
          </span>
        </div>

        <div className="max-w-md mx-auto flex p-2 mt-8 mb-6 bg-green rounded-lg justify-center">
          {stars.map((item, index) => {
            return (
              <span key={`index-${index}`}>
                <img
                  className="cursor-pointer w-10 h-10"
                  src={item <= selectedCount ? selectedStar : unSelectedStar}
                  onClick={() => this.onRate(item)}
                />
              </span>
            )
          })}
        </div>

        <div className="max-w-md mx-auto flex p-2 mt-10 bg-green rounded-lg">
          {selectedCount !== 0 && (
            <Button
              label="Submit"
              width="80%"
              noBorder
              backgroundColor="#ffdf71"
              onClick={this.submit}
            />
          )}
        </div>
      </div>
    )
  }
}

RateApp.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.func.isRequired,
  setFeedback: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps,
  {
    serviceRequest,
    setFeedback
  }
)(RateApp)