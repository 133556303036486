// export const apiBaseUrl = "http://192.168.101.38:8000/api"
export const apiBaseUrl = "https://admin.riserunsports.com/api"
export const appBaseUrl = "https://soccersubs.riserunsports.com/"
export const appStatsBaseUrl = "https://soccerstatstracker.riserunsports.com/"
export const possiblePostions = ["CB", "LB", "RB", "LM", "CM", "RM", "LF", "CF", "RF", "ST", "GK"]

const googleClientId = "405892288966-4sre3m57n2ofjg1ftn80qb5nmce5js3r.apps.googleusercontent.com"
const facebookClientId = "569250840555216"


const LINKEDIN_APP_CLIENT_ID = '86iwezjntavayu'
const LINKEDIN_APP_CLIENT_SECRET = 'qABetM3ENIWWOBZh'
const LINKEDIN_APP_REDIRECT_URI = 'http://localhost:3000/linkedin'

export const ClientIDs = {
    googleClientId,
    facebookClientId,
    LINKEDIN_APP_CLIENT_ID,
	LINKEDIN_APP_REDIRECT_URI,
	LINKEDIN_APP_CLIENT_SECRET
}

export const posOptions = [
  {id: 0, name: "Left Def", abbr: "LB"},
  {id: 1, name: "Center Def", abbr: "CB"}, 
  {id: 2, name: "Right Def", abbr: "RB"}, 
  {id: 3, name: "Left Mid", abbr: "LM"}, 
  {id: 4, name: "Center Mid", abbr: "CM"}, 
  {id: 5, name: "Right Mid", abbr: "RM"}, 
  {id: 6, name: "Left Fwd", abbr: "LF"}, 
  {id: 7, name: "Center Fwd", abbr: "CF"}, 
  {id: 8, name: "Right Fwd", abbr: "RF"}, 
  // {id: 9, name: "Striker", abbr: "ST"}, 
  {id: 9, name: "Goal Keeper", abbr: "GK"}
]

const API_KEY='AIzaSyAZaVMFfJ-vAfjKb6yxH8I--vxcLH5wJdc'
const AUTH_DOMAIN='riserunstats.firebaseapp.com'
const DATABASE_URL='https://riserunstats.firebaseio.com'
const PROJECT_ID='riserunstats'
const STORAGING_BUCKET='riserunstats.appspot.com'
const MESSAGE_SENDER_ID='451431207347'
const APP_ID='1:451431207347:web:73fd3711e2a4d522b97bc6'
const MEASUREMENT_ID='G-1J2J064Y5J'

export const firebaseConfig = {
	apiKey: API_KEY,
	authDomain: AUTH_DOMAIN,
	databaseURL: DATABASE_URL,
	projectId: PROJECT_ID,
	storageBucket: STORAGING_BUCKET,
	messagingSenderId: MESSAGE_SENDER_ID,
	appId: APP_ID,
	measurementId: MEASUREMENT_ID
}

export const googlePlayUrl =
  'https://play.google.com/store/apps/details?id=com.riserunsports.soccersubs'
export const appleStoreUrl =
  'https://apps.apple.com/us/app/soccer-substitution-manager/id1501269548'

