const rowObj = {
  managerName: "",
  managerEmail: "",
}

export const stateObj = {
  formControls: rowObj,
  formErrors: rowObj,
  formInvalid: false,
}
