import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styles from "./styles"
import Button from "../../components/buttons/button"
import { serviceRequest } from "../../../actions/User"

const plusIcon = require("../../../images/icons/plus.png")
const cancelIcon = require("../../../images/icons/cancel.png")

class AutoSetupTeam extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      athletes: {},
      athleteRows: 0
    }
    this.onClickGoHome = this.onClickGoHome.bind(this)
    this.onClickAddRow = this.onClickAddRow.bind(this)
    this.onClickRemoveRow = this.onClickRemoveRow.bind(this)
    this.onChangeEmail = this.onChangeEmail.bind(this)
  }

  componentDidMount() {
    const { athletes, athleteRows } = this.state
    
    this.setState({
        athletes: {
            ...athletes,
            [`email${athleteRows}`]: ""
        },
        athleteRows: athleteRows + 1
    })
  }

  onClickGoHome() {
    this.props.history.push("add-athlete")
  }

  onClickAddRow() {
    const { athletes, athleteRows } = this.state
    if (athleteRows < 25) {
        
        this.setState({
            athletes: {
                ...athletes,
                [`email${athleteRows}`]: ""
            },
            athleteRows: athleteRows + 1
        })
    }
  }


  onClickRemoveRow = (index) => {
    const { athletes } = this.state;
    const keys = Object.keys(athletes)
    const values = Object.values(athletes)
    keys.splice(index, 1)
    values.splice(index, 1)
    let tmpObj = {}
    keys.map((key, i) => {
        tmpObj = {
            ...tmpObj,
            [key]: values[i]
        }
    })
    this.setState({ athletes: tmpObj })
  }

  onChangeEmail = (e) => {
      const { target } = e
      const { name, value } = target
      const { athletes } = this.state
      this.setState({
          athletes: {
              ...athletes,
              [name]: value
          }
      })
  }

  render() {
    const { location } = this.props
    const { state } = location
    const { athletes } = this.state
    const emails = Object.values(athletes)
    return (
      <div style={styles.container}>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg">
          <table className="text-left w-full border-collapse bg-white">
            <tbody>
              <tr>
                <td className="w-1/2 py-2 px-1 border border-blue-900 text-right">
                    <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold">Team Name</span>
                </td>
                <td className="w-1/2 py-2 px-1 border border-blue-900">
                  <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{state && state.createdTeam.team_name}</span>
                </td>
              </tr>
              <tr>
                <td className="py-2 px-1 border border-blue-900 text-right">
                  <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold">Team Gender</span>
                </td>
                <td className="py-2 px-1 border border-blue-900">
                  <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{state && state.createdTeam.team_gender}</span>
                </td>
              </tr>
              <tr>
                <td className="py-2 px-1 border border-blue-900 text-right">
                  <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold">Average Age</span>
                </td>
                <td className="py-2 px-1 border border-blue-900">
                  <span className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">{state && state.createdTeam.average_age}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex px-2 py-3 bg-green rounded-lg">
          <span className="px-2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">* Most teams have 25 up to athletes!</span>
        </div>

        <div>
        <div className="max-w-sm xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2">
            <table className="text-left w-full border-collapse ">
                <thead className="bg-grey-500 border border-black">
                    <tr>
                    <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl text-grey-dark border-b border-blue-900">
                        Athlete Email
                    </th>
                    <th className="py-4 px-1 bg-grey-lightest border-b border-l border-blue-900 w-20">
                        <div className="w-full h-full flex justify-center items-center">
                        <img
                            className="cursor-pointer w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 lg:w-6 lg:h-6"
                            src={plusIcon}
                            onClick={this.onClickAddRow}
                        />
                        </div>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {emails.length !== 0 && emails.map((item, index) => (
                        <tr key={index}>
                            <td className="border border-blue-900">
                            <input
                                name={`email${index}`}
                                value={item}
                                className="w-full h-8 px-2 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl"
                                placeholder={`Athlete Email${index + 1}`}
                                onChange={(e) => this.onChangeEmail(e)}
                            />
                            </td>
                            <td className="px-1 border border-blue-900 w-20">
                            <div className="w-full h-full flex justify-center items-center">
                                <img
                                className="cursor-pointer w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6 md:w-6 md:h-6 lg:w-6 lg:h-6"
                                src={cancelIcon}
                                onClick={() => this.onClickRemoveRow(index)}
                                />
                            </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        <div
            className="max-w-xs xl:max-w-2xl lg:max-w-xl md:max-w-md sm:max-w-sm mx-auto flex p-2 bg-green rounded-lg"
            style={{ marginTop: 40, paddingBottom: 40 }}
        >
          <Button
            label="Save"
            width="100%"
            backgroundColor="#ffffff"
            margin={"20px"}
            onClick={this.onClickSaveManagers}
          />
          <span style={{ width: "10%" }}></span>
            
          <Button
            label="Exit"
            width="100%"
            backgroundColor="#ffffff"
            margin={"20px"}
            onClick={this.onClickGoHome}
          />
        </div>
        </div>
      </div>
    )
  }
}

AutoSetupTeam.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  serviceRequest: PropTypes.instanceOf(Object.isRequired)
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
  
export default connect(
    mapStateToProps,
    {
        serviceRequest
    }
)(AutoSetupTeam)