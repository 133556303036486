export const formObj = {
  email: "",
  password: "",
}

export const stateObj = {
  formControls: formObj,
  formErrors: formObj,
  formInvalid: false,
}
