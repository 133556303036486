import React, { Component } from "react"
import PropTypes from "prop-types"
import { Droppable } from "react-beautiful-dnd"

import GridItem from "./gridItem"

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#edf2f7"
})

class GridColumn extends Component {
  static propTypes = {
    droppableId: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired
  }

  render() {
    const { droppableId, data, ...props } = this.props
    return (
      <Droppable droppableId={droppableId} >
        {(provided, snapshot) => (
          <div
            className="w-full"
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {data.length === 0 && (
              <div className="w-full h-12" />
            )}
            {data.map((item, index) => {
              if (props.isRunning) {
                if (item.ready) {
                  return <GridItem item={item} index={index} key={item.id} {...props} />
                }
              } else {
                return <GridItem item={item} index={index} key={item.id} {...props} />
              }
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }
}

export default GridColumn
